import React from 'react';
import { NavLink } from 'react-router-dom';

export const TurtleIntro = () =>{
    return(
        <div className="turtle-page__intro">
                <div className ="turte-page__desc-wrap">
                    <p className="turtle-page__description">
                    Интерактивный тренажер, где вы можете проходить несложные логические задания и прокачивать свой скилл программирования.
            Управлять этим очень просто:
                    </p>
                    <ul className="turtle-page__work-desc-list">
                        <li className="turtle-page__work-desc">
                            <img className="turtle-page__icon" srcSet="./img/turtleEx/fwd.png" alt="иконка вверх"/> 
                            <span className="turtle-page__icon-desc">— движение вверх</span>
                        </li>
                        <li className="turtle-page__work-desc">
                            <img className="turtle-page__icon" srcSet="./img/turtleEx/dwn.png" alt="иконка вниз"/> 
                            <span className="turtle-page__icon-desc">— движение вниз</span>
                        </li>
                        <li className="turtle-page__work-desc">
                            <img className="turtle-page__icon" srcSet="./img/turtleEx/left.png" alt="иконка влево"/> 
                            <span className="turtle-page__icon-desc">— движение влево</span>
                        </li>
                        <li className="turtle-page__work-desc">
                            <img className="turtle-page__icon" srcSet="./img/turtleEx/right.png" alt="иконка направо"/> 
                            <span className="turtle-page__icon-desc">— движение направо</span>
                        </li>
                        <li className="turtle-page__work-desc">
                            <img className="turtle-page__icon" srcSet="./img/turtleEx/cactus.png" alt="иконка лавы"/> 
                            <span className="turtle-page__icon-desc">— ЛАВА! Коснёшься – проиграл.</span>
                        </li>
                        <li className="turtle-page__work-desc">
                            <img className="turtle-page__icon" srcSet="./img/turtleEx/leaves.png" alt="иконка травы"/> 
                            <span className="turtle-page__icon-desc">— трава – можно идти!</span>
                        </li>
                        <li className="turtle-page__work-desc">
                            <img className="turtle-page__icon" srcSet="./img/turtleEx/target.png" alt="цель к которой мы идём"/> 
                            <span className="turtle-page__icon-desc ">— цель к которой нужно придти</span>
                        </li>
                    </ul>
                        <NavLink 
                        to="/turtle/602f34871aa9b840d0609335" 
                        activeClassName="lessons-link__current" 
                        id="start_btn" 
                        className="homing-btn homing-btn-yellow">
                            Начать</NavLink>
                </div>
            
                <div className="turtle-example">
                    <p className="turtle-example__desc">
                    Перетаскивая блоки из одного окна в другое, собирайте логическую цепочку для  достижения цели.
                    </p>
                    <img srcSet="./img/turtleEx/turtlegif.gif"  alt="просто перетаскивайте блоки-команды"/>
                </div>
            </div>
    )
}