import React, {useCallback} from "react"
import { useHttp } from "../../../hooks/http.hook";

export const DeleteBtn = user => {
    const {request} = useHttp();
    const token = JSON.parse(localStorage.getItem("userData")).token;

    const removeUser = useCallback((e)=>{
        const body = {
            id: e.target.id
        }

        request(user.route, 'DELETE', body, {Authorization: token}).then(data=>{
            window.M.toast({html: data.message})
        })
     
    },[request,token,user])

    return (
        <button id={user.id} className="btn-small red waves-effect waves-light" onClick={removeUser}>Del</button>
    )
}