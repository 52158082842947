import React, { useCallback, useEffect, useState } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { TransactionListItem } from './components/transaction-list-item';

export const TransactionsList = () => {
    const [data, setData] = useState(null)
    const {request, token, loading} = useHttp();
    const GetList = useCallback(()=>{
        request('/api/auth/get-trans', 'GET', null, {Authorization: token})
            .then(data => setData(data))
    },[request,token])

    useEffect(()=>{
        GetList()
    },[GetList])
    
    return(
        <div className="container">
            <h1 className="profile-page__headline">Оплаты</h1>
            <div className="price-page__decoration-line profile-line"></div>
            {!loading && <TransactionListItem transactionList={data}/>}
        </div>
    )
}