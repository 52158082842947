import React from "react"

export const Variant = params =>{
    const data = {
        variant: params.variant,
        question: params.question,
        value: params.value,
        spanClass: params.isImage ? "image_variant" : ""
    }
    
        return(
            <div className={"variant_text"}>
                <label>
                    <input name={data.question} type="radio" value={data.value}/>
                    <span className={data.spanClass}>
                        {!params.isImage && data.variant}
                        {params.isImage && <div className="img-container"><img srcSet={data.variant} alt="вариант в виде картинки"/>
                            </div>}
                    </span>
                </label>
            </div>
        )
    }