import React from 'react'

export const Instructions = () =>{
    return (
        <div className="container">
            <h1 className="page-headline">Инструкции к установке</h1>
            <section className="instr-block">
                <h3>Главное Discord!</h3>
                <p>Для связи со студентами мы используем программу Discord (аналог скайпа).</p>
                <a className="btn indigo darken-3 waves-effect" href="https://discordapp.com/" target="_blank" rel="noopener noreferrer">Скачать Дискорд</a>    
            </section>
            <section className="instr-block">
                <h3>Если нужна помощь</h3>
                <p>Если вы боитесь, что не справитесь сами, то мы можем помочь установить и настроить всё дистанционно с помощью программы TeamViewer.</p>
                <a className="btn blue darken-3 waves-effect links-item" href="https://download.teamviewer.com/download/TeamViewer_Setup.exe" target="_blank" rel="noopener noreferrer">Скачать TeamViewer для Windows</a>
                <a className="btn blue lighten-1 waves-effect" href="https://download.teamviewer.com/download/TeamViewer.dmg" target="_blank" rel="noopener noreferrer">Скачать TeamViewer для Mac</a>
                <div className="img-wrap">
                    <img srcSet="img/TeamViewer_Setup.png" width="450px" alt=""/>
                </div>    
            </section>
            <section className="instr-block">
                <h3>Minecraft</h3>
                <p>Eсли ваш курс майнкрафт, уточните у преподавателя, что нужно именно вам.</p>
                <div className="row">
                    <a className="btn red darken-1 links-item" href="https://mcreator.net/download/">MCreator</a>
                    <a className="btn purple accent-1 links-item" href="https://drive.google.com/drive/folders/1sbZJVx_XpsrjWOQy0ybcvTGF6ufdyK6g">Черепашка и Инструкции по её установке</a>
                    <a className="btn teal darken-4 links-item" href="https://www.mann-ivanov-ferber.ru/assets/files/bookparts-new/programmiruem-s-minecraft/Minecraft_Tools.zip">Minecraft tools для Minecraft+Python</a>
                </div>
                <p>При загрузке <b>MCreator</b></p>
                <img srcSet="img/mcreator.png" alt=""/>
            </section>
            <section className="instr-block">
                <h3>Web</h3>
                <p>Для создания сайтов, для удобства, установите невероятный VSCode</p>
                <a className="btn blue" href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer">скачать VSCode</a>
            </section>
            <section className="instr-block">
                <h3>Unity</h3>
                <p>Для создания игр с помощью Unity нужен целый список программ. Среди них: 3D редактор <b>Magica Voxel</b> под вашу операционную систему, сам движок <b>Unity</b>, а также редактор кода <b>VSCode</b></p>
                <div className="row">
                    <a className="btn light-blue lighten-2 links-item" href="https://github.com/ephtracy/ephtracy.github.io/releases/download/0.99.4/MagicaVoxel-0.99.4.2-alpha-win64.zip" target="_blank" rel="noopener noreferrer">Magica Voxel win64</a>
                    <a className="btn green lighten-1 links-item" href="https://github.com/ephtracy/ephtracy.github.io/releases/download/0.99.4/MagicaVoxel-0.99.4.2-alpha-win32.zip" target="_blank" rel="noopener noreferrer">Magica Voxel win32</a>
                    <a className="btn blue links-item" href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer">скачать VSCode</a>
                    <a className="btn teal lighten-1" href="https://drive.google.com/u/0/uc?id=1uIes3H2ImfCk1MYzQdisksa9dHGBIcz1&export=download">Unity</a>
                </div>
                <div>
                    <p>При установке, выберем сам игровой движок и доп. компоненты для создания мобильных игр.</p>
                    <img srcSet="./img/unity_setup.png" alt=""/>
                </div>
            </section>
            <section className="instr-block">
            <h3>Python</h3>
                <p>Для изучения Python, вам потребуется установить сам язык на компьютер по  
                    <a className="btn teal lighten-1" href="https://www.python.org/ftp/python/3.8.2/python-3.8.2.exe" > ссылке</a></p>
                    <p>Дополнительно, по рекомендации преподавателя, может понадобиться <a className="btn green darken-1" href="https://repo.anaconda.com/archive/Anaconda3-2020.02-Windows-x86_64.exe" target="_blank" rel="noopener noreferrer">Anaconda</a> или 
                    <a className="btn yellow darken-3" href="https://download.jetbrains.com/python/pycharm-community-2019.3.4.exe"> PyCharm</a></p>
            </section>
        </div>

    )
}
