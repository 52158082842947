import React, { useState, useContext, useEffect } from 'react'
import {useHttp} from '../hooks/http.hook'
import { AuthContext } from '../context/auth.context';
import { useMessage } from '../hooks/message.hook';

export const AuthPage = ()=>{
    const auth = useContext(AuthContext);
    const {loading, error, request, clearError} = useHttp();
    const [form, setForm] = useState({
        email:'', password:''
    });

    const [reg, setReg] = useState(false); 

    const regHandler = () => {
        if(reg){
            registerHandler();
        }else{
            setReg(true);
        }
    }

    const logViewHandler = () => {
        setReg(false);
    }
    
    const message = useMessage();

    useEffect(() =>{
        message(error);
        clearError();
    },[error, message,clearError]);

    useEffect(() => {
        window.M.updateTextFields();
      }, []);

    const changeHandler = evt => {
        setForm({...form, [evt.target.name]: evt.target.value})
    }

    const loginHandler = async() => {
        try {
            const data = await request('/api/auth/login', 'POST',{...form})
            message(data.message);
            auth.login(data.token, data.name, data.isTeacher, data.isAdmin);
        }catch(e){
            console.log(e)
        }
    }

    const enterHandler = e =>{
        if(e.key === "Enter"){
            if(!reg){
                loginHandler();
            }else{
                registerHandler();
            }
        }
    }

    const registerHandler = async() => {
        try {
            await request('/api/auth/register', 'POST',{...form})
            const authData = await request('/api/auth/login', 'POST',{...form})
            auth.login(authData.token, authData.name)
        }catch(e){

        }
    }

    return (
        <div className="auth-modal" onKeyUp={enterHandler} key="Enter">
            <div className="card auth-card">
                
                    <span className="card-title">Авторизация в системе</span>
                    {reg&&(
                            <div className="row">
                            <div className="input-field col s6 auth_name-input">
                                <label htmlFor="first_name">Ваше Имя</label>
                                <input  id="first_name" name="userName" type="text" className="validate" onChange={changeHandler}/> 
                            </div>
                            <div className="input-field col s6">
                                <label htmlFor="second_name">Ваша Фамилия</label>
                                <input  id="second_name" name="userSurname" required type="text" className="validate" onChange={changeHandler}/>
                            </div>
                        </div>
                        )}
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="email" name="email" type="email" disabled={loading} onChange={changeHandler}/>
                            <label htmlFor="email">Email</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="password" name="password" type="password" disabled={loading} onChange={changeHandler}/>
                            <label htmlFor="password">Пароль</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="auth-page__buttons">
                            {reg&&<button className="auth__back-to-enter-btn" onClick={logViewHandler}>На страницу входа</button>}
                            <button onClick={regHandler} className={reg? "auth__reg-btn": "auth__back-to-enter-btn"}>Регистрация</button>
                            {!reg&&<button className="auth__enter-btn" onClick={loginHandler}>Войти</button>}
                        </div>
                    </div>
                </div>
            
        </div>
    
    )
}