import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import {useRoutes} from './routes'
import 'materialize-css'
import { useAuth } from './hooks/auth.hook';
import { AuthContext } from './context/auth.context';
import { Navbar } from "./pages/components/navbar";
import GoogleAnalytics from './pages/components/google-anltcs';

function App() {
  const {login,logout,userName,token,isAdmin,isTeacher} = useAuth()
  const isAuthenticated = !!token;
  const Route = useRoutes(isAuthenticated,isAdmin);
  return (
    <AuthContext.Provider value={
      {login,logout,isAuthenticated,userName,token,isAdmin,isTeacher}
    }>
      <Router>
        <Navbar token={isAuthenticated}/>
        <div>
            {Route}
        </div>
        <GoogleAnalytics />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
