import { useState, useCallback, useContext } from "react";
import { AuthContext } from "../context/auth.context"
import $api from "../service/api-service";

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');

  const auth = useContext(AuthContext);
  const clearError = useCallback(() => {
    setError(null);
  }, []);
  const request = useCallback(
    async (url, method = "GET", body = "null", headers = {}) => {
      setLoading(true);

      try {
        const response = await $api.request(url, {
          method,
          data: body,
          credentials: "include"
        });
        if(response.status === 401){
          window.M.toast({html: "Время сессии истекло"})
          auth.logout()
          clearError()
        }
        const data = await response.data;
        if (response.status === 500) {
          throw new Error(data.message || "Something went wrong");
        }
        setLoading(false);
        return data;
      } catch (error) {
        setLoading(false);
        setError(error.message);
        throw error;
      }
    },
    [auth, clearError]
  );

  return { loading, request, token, error, clearError};
};