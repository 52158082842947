import axios from 'axios';

const baseUrl = '';


let refreshTokenPromise;

const $api = axios.create({
    withCredentials: true,
    baseURL: baseUrl
  })

$api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    config.headers.authorization = token ? token : ''
    return config;
})

const getRefreshToken = async () =>{
    const response = await axios.get(`${baseUrl}/api/auth/refresh`, {withCredentials: true})
    const token = `Bearer ${response.data.accessToken}`
    localStorage.setItem('token', token);
    return token
}

$api.interceptors.response.use((config) => {
return config;
},async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && error.config) {
        if(!refreshTokenPromise){
            try {
                refreshTokenPromise = getRefreshToken()
                    .then((token)=>{
                        refreshTokenPromise = null
                        return token
                    })
            } catch (e) {
                console.log('НЕ АВТОРИЗОВАН');
            }
        }

        return refreshTokenPromise.then(token => {
            originalRequest.Authorization = token
            return $api.request(originalRequest);
         })
    }
    throw error;
})

export const FetchCreateProgress = (groupId) => {
    return $api.post('/progress/create/'+groupId,
    {courseName: "Питон Ур.1"});
}

export default $api
