import React, {  useState, useEffect, useContext } from "react"
import { NavLink } from "react-router-dom"
import { DeleteBtn } from "../../components/mini/deleteBtn"
import { AuthContext } from "../../../context/auth.context"
import { Lessons } from "../lessons"
export const LessonItem = data => {
    const [group] = useState({
        order:data.order,title:data.title
    })
    const [lessons,setLessons] = useState(null)
    const auth = useContext(AuthContext);


    useEffect(()=>{
        setLessons(data.tasksList.map((el,i)=>{return(<TaskItem key={i} {...el}/>)}))
    },[data.tasksList])  
    
    
    return (
        <div className="collection-item user-item" >
            <NavLink to={"/task/?"+data._id} >
                <div className="lesson-line">
                    <div className="lesson-line__order"><span>Урок {group.order}.</span></div>
                    <div className="lesson-line__title"><b>{group.title}</b></div>
                    {lessons}
                </div>
            </NavLink>
            {auth.isTeacher && 
            <div className="add-user-btn" onClick={Lessons.deleteLesson}>
                <DeleteBtn id={data._id} route="/api/lesson" />
            </div>}
        </div>
    )
}

const TaskItem = tasks => {
    return (
        <div className="lesson__task">
            <div><i className="task-tittle">"{tasks.name}"</i></div>
            <div>{tasks.task}</div>
        </div>
    )
}