import React from 'react'
import { Visitors } from './visits';

export const UserList = ({dataList, hoursList, schedule}) => {
    
    return (
        <div>
            {dataList.map((el,i)=>{
                return(
                <div className="user-list" key={i}>
                    <div className="user-list__item">{i+1}. {el.userName} {el.userSurname}</div>
                    {schedule
                    .map((schEl,schI)=>{
                        return <Visitors hoursList = {hoursList[schI]} userId = {el._id} checked={el.attendanceList.includes(schEl)} date={schEl} key={schI}/>
                    })}
                </div>)

            })}
        </div>
    )
}