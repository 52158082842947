import React, { useState, useCallback, useEffect} from "react"
import { Variant } from "./variant";
import { useHttp } from "../../../hooks/http.hook";
import { AchivmentPopUp } from "../../components/achivment-popup";


export const Exercise = (data)=>{
const {request, token} = useHttp();    

const [content] = useState({
    question: data.data.question, 
    variants: data.data.variants, 
    answer: data.data.answer, 
    id: data.data._id, 
    complited:data.data.complited, 
    isImage: data.data.isImage,
    isImageClass: data.data.isImage ? "img_variant_grid" : "",
    courseId: data.courseId
})

const ClosePopUp = ()=>{
    setAchivPopup("")
}

const [achivPopup, setAchivPopup] = useState()
const [complite, setComplite] = useState(content.complited);
const [misstake, setMisstake] = useState();
const rememberExercise = useCallback((btn)=>{
    request('/api/videolesson/exercise_complite/'+content.id, 'POST', {vLessonId: data.location, courseId: content.courseId}, {Authorization: token})
    .then((data)=>{
        if(!!data.headline){
            setAchivPopup(<AchivmentPopUp title={data.headline} description={data.description} image={data.image} closeFunc={ClosePopUp}/>)
        }
        setComplite(true);
    })
},[request,token, content.id, data.location,content.courseId])



useEffect(()=>{
    if(content.complited){
        document.querySelectorAll(`input[name="${content.question}"]`)[content.answer].parentNode.classList.add('truth_var');
        document.querySelectorAll(`input[name="${content.question}"]`).forEach(el=>{
            el.disabled = 'disabled'
        })
    }
},[content])

const checkHanler = (evt)=>{
   evt.target.disabled = true;
   const checkedVariant = document.querySelector(`input[name="${content.question}"]:checked`);
   const answer = checkedVariant !== null ? document.querySelector(`input[name="${content.question}"]:checked`).value : "не выделенно";
   if(+answer === content.answer){
        rememberExercise(evt.target);
        checkedVariant.parentNode.classList.add("truth_var");
        document.querySelectorAll(`input[name="${content.question}"]`).forEach(el=>{
            el.disabled = 'disabled'
        })
        setMisstake();
   }else{
        setComplite(false)
        setMisstake("misstake");
        evt.target.disabled = false
   }
}

    return(
        <div className="vlesson__exercise">
            <div className="exercise__headline">Задание</div>
            <p className="exercise__question">{content.question}</p>
                <div className={`variants_${complite} variants ${misstake} ${content.isImageClass}`}>
                    {content.variants.map((el,i)=>{
                        return <Variant variant={el} question={content.question} isImage={content.isImage} key={i} value={i}/>
                    })}
                <button  onClick={checkHanler} className={`btn btn-small waves-effect waves-light exercise_btn btn_${complite}`}>Проверить</button>
                </div>
                {achivPopup}
        </div>
        
    )
}