import React from 'react'
import { NavLink } from 'react-router-dom'
import { AuthPage } from './authPage'
import { MPCards } from './lessons/components/main-page_cards'

export const MainPage = (data)=>{  

    return (
        <div className="main-page__header">
            <div className="container main-page_wrap">
                <div className="main-page__cards-grid">
                    {!!data.auth &&
                    <MPCards headline="Курсы" 
                    content="Онлайн курсы без преподавателя по понятным видео-урокам и домашним заданиям."
                    linkTo="/courses"/>}
                    {!data.auth &&  
                    <div className="main-page__block">
                        <h1 className="main-page__headline">Yes.homing</h1>
                        <div className="page-block__image"><img src="../img/chat.png" alt="Online"/></div>
                        <p className="main-page__desc">Платформа онлайн обучения школы программирования <b>Yes.coding</b>.</p>
                        <p className="main-page__desc">Все те же крутые занятия, только <b>онлайн</b>!</p>
                        {!data.auth && <p className="main-page__desc">Для доступа в группу: <NavLink to="/"><br/> <b>войдите в личный кабинет</b></NavLink>!</p>}
                        {data.auth && <p className="main-page__desc"><NavLink to="/groups"><br/> <b>Перейдите в группы</b></NavLink></p>}
                    </div>}
                    {data.auth && <MPCards headline="Группы" blue={true} 
                    content="Для тех, кто уже занимается с нашими супер-героями преподавателями, и хотят дополнительно позаниматься дома."
                    linkTo="/groups"/>}
                    {data.auth && <MPCards headline="Тренажеры" 
                    content="Наш собственный тренажер. Увлекательная головоломка, где с помощью визуального программирования, можно прокачать логику"
                    linkTo="/turtle"
                    />}
                    {data.auth && <MPCards classContent={"main-page__trainers"} headline="Личный кабинет" blue={true} 
                    content="Какое, рассписание, когда были уроки, и какой баланс счета, всё можно узнать здесь!"
                    linkTo="/profile"/>}
                    {!data.auth && <AuthPage />}
                </div>
            </div>
            <div className="main-page__right-bg"></div>
        </div>
    )
}