import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useHttp } from '../hooks/http.hook';
import { Loading } from './components/mini/loading';

export const SuccessPage = ()=>{
    const {request, token, loading} = useHttp();
    const [payState, setPayState] = useState();
    const CheckPayStatus = useCallback(()=>{
        const orderId = document.location.search.match(/\?orderId=([a-z0-9-]+)&?/i)
        if(orderId){
            request('/api/auth/pay-check', 'POST', {orderId: orderId[1]}, {Authorization: token})
            .then(data=>{
                setPayState(data.message)}
            ).catch(data=>{
                setPayState(data.message)}
            )
        }else{
            setPayState("Строка запроса неверна!")
        }
    },[request, token])

    useEffect(()=>{
        CheckPayStatus();
    },[CheckPayStatus])

    return (
        <div className="container">
            <h1>Состояние оплаты</h1>
            <div>
                {loading && <Loading />}
                {!loading && <div>
                    <p>
                    {payState}</p>
                    Если вы оформляли подписку, перейдите на <NavLink to="/price">страницу подписок</NavLink> и ещё раз нажмите кнопку получить
                    </div>}
            </div>
        </div>
    )
}