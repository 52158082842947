import React, { useCallback, useEffect, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/auth.context';
import { useHttp } from '../../hooks/http.hook';
import { GoBackSider } from '../components/mini/gobacksider';
import { VlessonItem } from '../lessons/components/vlessonItem';

export const VideoCourseList = ()=>{
    const {request, token, loading, error, clearError} = useHttp();
    const location = document.location.search.slice(1);
    const auth = useContext(AuthContext);

    const [courseData, setCourseData] = useState([]);
    const [vlesson, setVlesson] = useState([]);
    const getVlessons = useCallback(()=>{
        request('/api/course/'+ location, 'GET',null, {Authorization: token})
        .then(data =>{
            setCourseData(data[0]);
            setVlesson(data[1]);
        })
        .catch(res=>{console.log(res)});
    },[request,location, token]);

    useEffect(()=>{
        if(error){
        window.M.toast({html: error})
        clearError()
        }
    }, [error, clearError])

    useEffect(()=>{
        getVlessons()
    },[getVlessons])

    return(
        <div className="container">
            <div className="course-headline">
                <img src="/img/course-icons/scratch_start.svg" alt="Scratch. Начало"/>    
                <h1 className="page-headline">{!loading&&courseData.name}</h1>
            </div>
            <p className="course-inner__description">
            {!loading && courseData.innerDescription}
            </p>
            <h2 className="section__headline">Уроки</h2>
            {!loading && <VlessonItem vLessonsData={vlesson} location={location} auth={auth} courseId={location}/>}
            <div className="goback_container">
                <NavLink className="back_btn" to={'/courses'}>К курсам</NavLink>
            </div>
            <GoBackSider path="/courses" />
        </div>
    )
}