import React, { useState } from "react";
import ".././style.css";
import Question from "./src/question";
import RegForm from "./src/regForm";

const QuizPage = ({match}) => {
    const dataList = [
        {
            step: "age",
            question: "Сколько лет будущему программисту?",
            answers: ["От 7 до 9", "от 9 до 11", "от 11 до 17", "Взрослый"],
            nextLink: 1
        },
        {
            step: "exp",
            question: "Опыт в программировании",
            answers: ["Совсем нет", "Есть минимальный", "Опытный пользователь", "Эксперт"],
            nextLink: 2
        },
        {
            step: "favorite",
            question: "Любимый предмет в школе",
            answers: ["Математика", "Рисование", "Биология", "Литература"],
            nextLink: "reg"
        },
    ]

    const [userDataList, setUserDataList] = useState({
        age: "",
        exp: "",
        favorite: "",
    })

    const dataSetter = (param, data) => {
        setUserDataList({...userDataList, [param]: data})
    }

    const progressValue = match.params.id !== "reg" ?  match.params.id*25 : 90;

    return(
        <div>
            <div className="quiz-page__progress-bar">
                <div className={"progress-bar__progress progress-bar__progress--"+progressValue}></div>
            </div>
            <div className="quiz-page__wrap">
                <div className="start__container">
                    <div>
                        {
                            match.params.id === "reg" ? 
                            <RegForm quizData={userDataList}/> :
                            match.params.id < dataList.length && 
                                <Question dataSetter={dataSetter} data={dataList[match.params.id]}/>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default QuizPage;