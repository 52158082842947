import React, { useEffect, useState, useCallback, useContext } from 'react'
import {useHttp} from '../../hooks/http.hook'
import { LessonItem } from './components/lessonItem';
import { AuthContext } from '../../context/auth.context';
import { VlessonItem } from './components/vlessonItem';
import { AddLesson } from './components/addLesson';
import { UserList } from './components/userList';
import { Loading } from '../components/mini/loading';
import { useMessage } from '../../hooks/message.hook';
import { NavLink } from 'react-router-dom';

export const Lessons = ()=>{
    const {request, token, loading, error, clearError} = useHttp();
    const [lessonData,setLessonsData] = useState([])
    const [vLessonsData, setVLessonsData] = useState([])
    let location = document.location.search.slice(1);
    const auth = useContext(AuthContext);
    const [discordLink,setDiscordLink] = useState(null)
    const [users,setUsers] = useState([])
    const [scheduleData, setScheduleData] = useState({
        schedule: [], hoursList: []
    })
    const discordData = useCallback(()=>{
        request('/api/groups/'+location, 'GET', null, {Authorization: token})
            .then(data=>{
                setScheduleData( {schedule: data.lessonsList, hoursList: data.hoursList});
                setDiscordLink(data.discordId);
            });
    },[token,request,location])

    const getUsers = useCallback(()=>{
        if(auth.isTeacher || auth.isAdmin){
        request('/api/user/'+location, 'GET',null, {Authorization: token}).then(data=>{
            setUsers(
            data.map((el)=>{
                return el
            })
        )})}
    },[token,request,location, auth])

    const message = useMessage();

    useEffect(() =>{
        message(error);
        clearError();
    },[error, message,clearError]);

    useEffect(() => {
        window.M.updateTextFields();
      }, []);
    
    useEffect(()=>{
        const scrollCash = JSON.parse(localStorage.getItem('pagesHeightCash'))
        if(scrollCash){
            window.scrollTo(0,scrollCash.lessonsPage)
        }
    },[vLessonsData])
    

    const render = useCallback(()=>{
        if(localStorage.getItem('vlessonData')){
            setVLessonsData(JSON.parse(localStorage.getItem('vlessonData')))
        }

        request('/api/lesson/'+location, 'GET',null, {Authorization: token}).then(data => {
            renderFromData(data)
        })

        function renderFromData (data){
            setLessonsData(Array.from(data).map((el,i)=>{
                return <LessonItem key={i} {...el}/>
            }))
        }

        request('/api/videolesson/group/'+location, 'GET', null, {Authorization: token}).then(
            data => {
                setVLessonsData(data);
                localStorage.setItem('vlessonData', JSON.stringify(data))
            }
        )
    },[location,request,token])
    const dateEnum = 
        scheduleData.schedule.map((el,i)=>{
            return (
                <div className="date-item" key={i}>{el}</div>
            )
        })
    
    Lessons.deleteVideoLesson = useCallback(({target})=>{
        setVLessonsData(prev=>{
            return prev.filter(el=>{
                return el._id !== target.parentNode.getAttribute('vlesso_id')
            })
        })
        },[]
    )

    Lessons.createGroup = useCallback((form, task)=>{
            const body = {title:form.title, task:{...task}};
            request('/api/lesson/'+location, 'POST',{...body}, {Authorization: token}).then((data, res)=>{
                document.querySelectorAll('input').forEach(e=>{
                    e.value = ""
                })
                document.querySelector("textarea").value = ""
                window.M.toast({html: "Урок создан"})
                setLessonsData(prev=>{return prev.concat(<LessonItem {...data} key={data._id}/>)})
            })
    },[location, request, token])

    Lessons.deleteLesson = useCallback(({target})=>{
        setLessonsData(prev=>{
            return prev.filter(el=>{
                return el.props._id !== target.id
            })
        })
    },[])

    const addShadule = useCallback((e) =>{
        const inpt = document.getElementById("shadule_new_date").value
        const inptHours = document.getElementById("shadule_new_hours").value
        request('/api/groups/shedule/'+location,'PATCH', {newShadule:inpt,newHours:inptHours} ,{Authorization: token})
        .then(res=>{
                discordData()
                window.M.toast({html: res.message})})      
    },[location,request,token,discordData])

    const removeOneShadule = useCallback((e) =>{
        const inpt = document.getElementById("shadule_new_date").value
        request('/api/groups/shedule/'+location,'DELETE', {newShadule:inpt} ,{Authorization: token})
        .then(res=>{discordData(); window.M.toast({html: res.message})})      
    },[location,request,token,discordData])

    const removeAllShadule = useCallback((e) =>{
        const inpt = document.getElementById("shadule_new_date").value
        request('/api/groups/shedule/all/'+location,'DELETE', {newShadule:inpt} ,{Authorization: token})
        .then(res=>{discordData(); window.M.toast({html: res.message})})      
    },[location,request,token,discordData])

    useEffect(()=>{
        discordData()
    },[discordData,addShadule])

    useEffect(()=>{
        getUsers()
    },[getUsers])

    useEffect(()=>{
        render()
    },[render])

    const goLessonHandler = ()=>{
        localStorage.setItem('pagesHeightCash', JSON.stringify({lessonsPage: window.scrollY}))}

    return (
        <div className="container p-t lesson__page">
            <h1 className="page-headline">Уроки Группы</h1>
            {auth.isTeacher&&
            <div className="users-wrap">
                <div className="add-shedule">
                    <input id="shadule_new_date" type="text" placeholder="17.10"/>
                    <input id="shadule_new_hours" type="text" placeholder="часы"/>
                    <button className="btn-small" onClick={addShadule}>Добавить урок</button>
                    <button className="btn-small yellow darken-2" onClick={removeOneShadule}>Удалить последний</button>
                    <button className="btn-small red" onClick={removeAllShadule}>Удалить все</button>
                </div>
                <div className="shedule-line">
                    <h5>Студенты группы</h5>
                    {dateEnum}
                </div>
                {users.length !== 0 && <UserList dataList={users} {...scheduleData}/> }
            </div>}
            {!!discordLink&&<>
                <b>Ссылка на группу: </b><a href={discordLink} target="_blank" rel="noopener noreferrer">  Нажми на меня</a>
            </>}
            {auth.isTeacher&& <AddLesson />}   
            {vLessonsData !== 0 && <div >
                <div className="vlesson-menu__headline">
                    <h3 className="section__header">Видео Уроки</h3>
                    {auth.isTeacher && <NavLink className="btn vlesson-menu__btn" to={"/vlesson/add?"+location}>+</NavLink>}
                </div>
                <div className="vlessons-list" onClick={goLessonHandler}>
                    {loading && <div className="group__loading"><Loading/></div>}
                    <VlessonItem auth={auth} location={location} vLessonsData={vLessonsData}/>
                </div>
            </div>}
            {lessonData.length !== 0 &&<>
            <h3 className="section__header">Задания от преподавателя</h3>
            <div className="collection">{lessonData}</div>
            </>
            }
        </div>
    )
}
