
export const ctxController = ({props, AccessHandler}) => {
    const canvas = document.querySelector("canvas");
    const ctx = canvas.getContext('2d');
    let review = false;
    let parent = document.querySelector("#parent");
    //imgs
    const leaves = document.querySelector('#leaves');
    const golang = document.querySelector('#golang');
    const cactus = document.querySelector('#cactus');
    let Stop = false
    const Walls_position = []

    const Wall_create = (posX, posY) => {
        ctx.drawImage(cactus,35.5+posX*50, 35.5+posY*50, 46, 46)
        Walls_position.push({
            x1: 35.5+posX*50,
            x2: 35.5+posX*50 + 46,
            y1: 35.5+posY*50,
            y2: 35.5+posY*50 + 46
        })
    }

    const CollisionDetect = (posX, posY) =>{
            Walls_position.forEach((el,i)=>{
                if(posX+1 >= el.x1 && posX <= el.x2 && posY+1 >= el.y1 &&  posY <= el.y2){
                    Stop = true
                    ctx.fillStyle = "#fff"
                    ctx.fillRect(50, 145, 300, 75)
                    ctx.fillStyle = "#000"
                    ctx.font = "48px sans-serif";
                    ctx.fillText("Поражение", 70, 200)
                }
            })
    }

    const startCoord = 
        {
            turtleStartX: 35.5 + props.turtleStartX * 50,
            turtleStartY: 35.5 + props.turtleStartY * 50,
            targetStartX: 33.5 + props.targetStartX * 50,
            targetStartY: 33.5 + props.targetStartY * 50,
            finishAtX: 35.5 + props.targetStartX * 50,
            finishAtY: 35.5 + props.targetStartY * 50,
            lavaPos: props.lavaPos || undefined
        }
    

    canvas.width = 400;
    canvas.height = 400;

    ctx.imageSmoothingEnabled = false;
	ctx.mozImageSmoothingEnabled = false;
	ctx.msImageSmoothingEnabled = false;
    ctx.webkitImageSmoothingEnabled = false;
    
    let turtle={
        posX: startCoord.turtleStartX,
        posY: startCoord.turtleStartY,
        drowing: ()=>{
            ctx.drawImage(golang, turtle.posX, turtle.posY, 45,45)
        },
        clearPos: ()=>{
            turtle.posX=startCoord.turtleStartX;
            turtle.posY=startCoord.turtleStartY;
            turtle.drowing()
        },
        moveUp: ()=>{
            return new Promise(resolve =>{
            setTimeout(()=>{
                placeDrowing()
                turtle.posY-=50;
                turtle.drowing();
                CollisionDetect(turtle.posX, turtle.posY)
                resolve()
            }, 300)             
        })},
        moveRight:  ()=>{
            return new Promise(resolve =>{
                setTimeout(()=>{
                    placeDrowing()
                    turtle.posX+=50;
                    turtle.drowing();
                    CollisionDetect(turtle.posX, turtle.posY)
                    resolve()
                }, 300) 
            })
        },
        moveLeft: ()=>{
            return new Promise(resolve =>{
                setTimeout(()=>{
                    placeDrowing()
                    turtle.posX-=50;
                    turtle.drowing(); 
                    CollisionDetect(turtle.posX, turtle.posY)                
                    resolve()
                }, 300)
            })  
        },
        moveDown: ()=>{
            return new Promise(resolve =>{
                setTimeout(()=>{
                    placeDrowing()
                    turtle.posY+=50;
                    turtle.drowing();   
                    CollisionDetect(turtle.posX, turtle.posY)
                    resolve();
                }, 300)
            })   
        }
    }

    function repHandler(state) {
        let sintaxArr =["rep","do","end"]
        let inState = -0.1;
        let allRight = true;
        sintaxArr.forEach((el)=>{
            inState < state.indexOf(el) ? inState = state.indexOf(el) :  allRight = false;
            inState = state.indexOf(el)
            if(inState === -1||!allRight){
                return false;
            }
        })
        allRight = state.filter(el=> el === "rep").length === state.filter(el=>el === "end").length && state.filter(el=>el === "rep").length === state.filter(el=>el === "do").length;  
        if(allRight){
            let expectNum = ()=>{
                let check = true;
                state.forEach((el,i)=>{
                    if(el==="rep"){
                        if(!Boolean(parseInt(state[i+1]))){
                            check =false
                        }
                    }
                })
                return check;
            }
            allRight = expectNum()
        }
        return allRight;
    }

    //Проверка
    
    function reviewCheck(){
        review = turtle.posX === startCoord.finishAtX && turtle.posY === startCoord.finishAtY;
        if(review){
            parent.classList.remove('false');
            parent.classList.add('truth');
            ctx.fillStyle = "#fff";
            ctx.fillRect(75, 145, 250, 75);
            ctx.fillStyle = "#000";
            ctx.font = "48px sans-serif";
            ctx.fillText("Победа", 115, 200);
            AccessHandler();
        }else{
            parent.classList.remove('truth');
            parent.classList.add('false');
        } 
    }

    function clear(){
        placeDrowing();
        turtle.clearPos();
    }
    
    function placeDrowing(){
        ctx.clearRect(0,0,canvas.width,canvas.height);
        ctx.lineWidth = 7;
        ctx.strokeStyle ="#696969"
        ctx.strokeRect(30.5, 30.5, 355, 355);
        ctx.strokeStyle ="#262626"
        ctx.strokeRect(32.5, 32.5, 351, 351);
        ctx.drawImage(leaves, 34.5, 34.5, 347, 347);
        gridDrowing();
        ctx.lineWidth = 5;
        ctx.strokeStyle = "red";
        ctx.strokeRect(startCoord.targetStartX+1,startCoord.targetStartY+1, 48,48);
    }   

    function gridDrowing(){
        for(let x=85; x<375; x+=50){
            ctx.moveTo(x,35);
            ctx.lineTo(x,381.7);
        }

        for(let y=82.5;y<375; y+=50){
            ctx.moveTo(35,y);
            ctx.lineTo(381.7,y);
        }
        ctx.lineWidth=3;
        ctx.strokeStyle = "#888";
        ctx.stroke();

        if(startCoord.lavaPos !== undefined){
            startCoord.lavaPos.forEach(el=>{
                Wall_create(el[0], el[1])
            })
        }
    }

    function stuckToRun(firstArr){
    let StuckBeforeRun = firstArr;
    let middleStuck=[];
        while(checkNumber(StuckBeforeRun) ||StuckBeforeRun.indexOf("rep")>=0 || StuckBeforeRun.indexOf("do")>=0 || StuckBeforeRun.indexOf("end")>=0){
            for(let idx = 0; idx<StuckBeforeRun.length; idx++){
                let el = StuckBeforeRun[idx];
                if(el === 'end'|| el === 'do'){
                    alert("Ошибка в синтаксисе цикла\nПоследовательность должна быть следующая:\nrep, 5, do, <команды для повторения>, end");
                    return [];
                }else if(parseInt(el)){
                    alert("Ошибка в синтаксисе цикла\nПоследовательность должна быть следующая:\nrep, 5, do, <команды для повторения>, end");
                    return [];
                }else if(el === 'fwd'|| el ==='right' || el === 'left'|| el === 'dwn'){
                    middleStuck.push(el)
                }else if(el==="rep"){
                    if(!(repHandler(StuckBeforeRun))){
                        alert("Ошибка в синтаксисе цикла\nПоследовательность должна быть следующая:\nrep, 5, do, <команды для повторения>, end");
                        return [];
                    }
                    let loopStuck = []
                    let loopStart = idx+3;
                    let loopTimes = parseInt(StuckBeforeRun[idx+1]);
                    let endFind = 1;
                    let i = 0;
                    let elem;
                    let elemEnd;
                    while(endFind>0){
                        elemEnd = loopStart+i
                        elem = StuckBeforeRun[elemEnd];
                        i++;
                        if(elem === "rep"){
                            endFind++;
                        }
                        if(elem === "end"){
                            endFind--;
                        }
                        if(endFind>0||!(elem === "end")){
                            loopStuck.push(elem);
                        }
                    }
                    let endLoopStuck = [];
                    for(let i = 0; i < loopTimes;i++){
                        endLoopStuck = endLoopStuck.concat(loopStuck);
                    }
                    let loopLenght = elemEnd-idx;
                    let loopMidleStuck = []
                    endLoopStuck.forEach((el)=>{
                        loopMidleStuck.push(el);   
                    })

                    middleStuck = middleStuck.concat(loopMidleStuck)
                    idx+=loopLenght;
                }
            }
        StuckBeforeRun = middleStuck;
        middleStuck = [];

        }
    return StuckBeforeRun;
    }

    function checkNumber(arr){
        let result = false
        arr.forEach(el=>{
            if(Boolean(parseInt(el))){
                result = true;
            }
        })
        return result;
    }

    let active = false;


    return {
        Start: function (){
            Stop = false;
            if(!active){
                active = true;
                
                let resultArr = [];
                let runStack=[];
                let checking = parent.querySelectorAll('.checher');
                checking.forEach((elem)=>{
                    resultArr.push(elem.getAttribute('data-info'))
                })
                clear();
                let arrStackToRun = stuckToRun(resultArr)
                arrStackToRun.forEach((elem,i)=>{
                    if(elem === 'fwd'){
                        runStack.push(turtle.moveUp)
                    }
                    else if(elem === 'right'){
                        runStack.push(turtle.moveRight)
                    }
                    else if(elem === 'left'){
                        runStack.push(turtle.moveLeft)
                    }
                    else if(elem === 'dwn'){
                        runStack.push(turtle.moveDown)
                    }
                    //desription for all actions
                })
        
                async function run(){
                    for(let elem of runStack){
                        if(Stop){
                            return
                        }
                       await elem(); 
                    }
                }
                run().then(()=>reviewCheck()).then(()=>active = false);
            }
        },
        doClear: function(){clear()}
    }
}