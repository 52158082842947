import {useState, useCallback, useEffect} from 'react'

export const useAuth = () =>{
    const [token, setToken] = useState(null)
    const [userName, setUserName] = useState(null)
    const [isAdmin, setAdmin] = useState(null)
    const [isTeacher, setTeacher] = useState(null)

    const login = useCallback((jwtToken, userName,teacher,admin, date = new Date())=>{
        localStorage.setItem('userData', JSON.stringify({name:userName,teacherPer:teacher,adminPerm:admin, date: +date}
            ))
        localStorage.setItem('token', jwtToken);
        setToken(jwtToken)
        setUserName(userName)
        setAdmin(admin)
        setTeacher(teacher)
    },[])

    const logout = useCallback(()=>{
        setToken(null)
        setUserName(null)
        setAdmin(null)
        setTeacher(null)
        localStorage.clear()
    },[])

    useEffect(()=>{
        const data = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');

        if(data && token) {
            login(token, data.name,data.teacherPer,data.adminPerm, data.date)
        }
    }, [login, logout])

    return {login,logout,token,userName,isAdmin,isTeacher}
}