import { useEffect } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

const GoogleAnalytics = () => {
    const gtag = window.gtag;
    const location = useLocation();
    const history = useHistory();
    useEffect(()=>{
        if (!location && !history) {
            return;
        }

        if (history.action === 'PUSH' &&
            typeof(gtag) === 'function') {
            gtag('config', 'G-9NWHV17NT8', {
                'page_title': document.title,
                'page_location': window.location.href,
                'page_path': location.pathname
            });
        }
    },[location, history, gtag])
    return null;
}

export default withRouter(GoogleAnalytics);
