import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {AuthPage} from './pages/authPage';
import {MainPage} from './pages/mainPage';
import {Groups} from './pages/groups/groups'
import { AddUser } from './pages/adduser';
import { Lessons } from './pages/lessons/lessons';
import { Tasks } from './pages/task';
import { Instructions } from './pages/instruction';
import { PermissionsPage } from './pages/permissions-page';
import { Profile } from './pages/profile/profile';
import { VideoLesson } from './pages/videolessons/videolesson';
import { AddVlessons } from './pages/videolessons/addVlessons';
import { Courses } from './pages/courses/courses';
import { VideoCourseList } from './pages/courses/videocourselist';
import { PricePage } from './pages/pricing/pricePage';
import { TurtlePage } from './pages/turtle-page/turtle-page';
import { TurtleEx } from './pages/turtle-page/turtle-ex';
import { TurtleIntro } from './pages/turtle-page/components/turtle-intro';
import { SuccessPage } from './pages/success-page';
import { TransactionsList } from './pages/transactions-list/transaction-list';
import { AddToCourse } from './pages/courses/addtocourse';
import StartPage from './pages/start-page';
import QuizPage from './pages/start-page/quiz';
import ThanksPage from './pages/start-page/thanks-page';

export const useRoutes = (isAuntificated, isAdmin) =>{
    if(isAuntificated){
        return (
            <Switch>
                {isAdmin && 
                <Route path="/permissions" exact>
                    <PermissionsPage/>
                </Route>}
                {isAdmin && 
                <Route path="/add_to_course" exact>
                    <AddToCourse />
                </Route>}
                {isAdmin &&
                <Route path="/trans-list" exact>
                    <TransactionsList />
                </Route>}
                <Route path="/profile" exact>
                    <Profile/>
                </Route>
                <Route path="/vlesson/add" exact>
                    <AddVlessons/>
                </Route>
                <Route path="/groups" exact>
                    <Groups />
                </Route>
                <Route path="/adduser">
                    <AddUser />
                </Route>
                <Route path="/lessons">
                    <Lessons />
                </Route>
                <Route path="/task">
                    <Tasks />
                </Route>
                <Route path="/instruction">
                    <Instructions />
                </Route>
                <Route path="/videolessons">
                    <VideoLesson />
                </Route>
                <Route path="/courses">
                    <Courses />
                </Route>
                <Route path="/videocourse">
                    <VideoCourseList />
                </Route>
                <Route path="/price" >
                    <PricePage />
                </Route>
                <Route path="/success-pay" >
                    <SuccessPage />
                </Route>
                <Route path="/thanks-page">
                    <ThanksPage />
                </Route>
                <Route path="/turtle" >
                    <TurtlePage children={
                        <Switch>
                            <Route exact path='/turtle' component={TurtleIntro} />
                            <Route path='/turtle/:id' component={TurtleEx } />
                        </Switch>
                    }/>
                </Route>
                <Route path="/" >
                    <MainPage auth={isAuntificated}/>
                </Route>
                <Redirect to="/" />
            </Switch>  
        )
    }
    return(
        <Switch>
            <Route path="/auth" exact>
                <MainPage auth={isAuntificated}/>
                <AuthPage/>
            </Route>
            <Route path="/start" exact>
                <StartPage/>
            </Route>
            <Route path="/begin-quiz/:id" component={QuizPage} />
            <Route path="/instruction">
                <Instructions />
            </Route>
            <Route path="/" >
                <MainPage auth={isAuntificated}/>
            </Route>
            {/* <Redirect to="/" /> */}
        </Switch>
    )

}