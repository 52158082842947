import React, { useEffect } from "react";
import { NavLink, Redirect, useLocation } from "react-router-dom";

const ThanksPage = () => {
    const location = useLocation();

    useEffect(()=>{
        const viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    },[])

    if(!location.state?.lesson) {
        return <Redirect to="/courses" />
    }

    return(
        <div className="start_wrap">
            <div className="start__container">
                <h2 className="thank-page__headline">Спасибо за регистрацию</h2>
                <div className="thanks-page__design">
                    <img className="thanks-page__img" alt="Спасибо за регистрацию" src="./img/hamster_hello.png" />
                </div>
                <p className="thank-page__description">
                На почту мы отправили вам данные для входа в аккаунт, чтобы вы их не потеряли и могли легко зайти на платформу с любого другого устройства.
                </p>
                <NavLink className="reg-form__submit thanks-page__btn" to={`/videolessons/?${location.state.lesson}?course=${location.state.course}`}>К первому уроку</NavLink>
            </div>
        </div>
    )
}

export default ThanksPage;