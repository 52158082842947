export const Dnd = () => {

document.removeEventListener("drag", null)
document.removeEventListener("drop", null)
document.removeEventListener("dragstart", null)
document.removeEventListener("dragover", null)
document.removeEventListener("dragleave", null)

let elemList = document.querySelectorAll('.checher')
let parent = document.querySelector('#parent')
let house = document.querySelector('#house')
let dragger;
let resultArr = []
let itemList = Array.from(elemList);
elemList.forEach(elem=> elem.setAttribute('draggable',true))


const swaping = function(e){
    let indexDrag = 0;
    let indexDrop = 0;

    if(e.target.parentNode === parent && dragger.parentNode === house){
        e.target.before(dragger);
        return
    }
    if((e.target.parentNode === parent && dragger.parentNode === parent) || (e.target.parentNode === house && dragger.parentNode === house)){
        indexDrag = Array.from(parent.childNodes).indexOf(dragger)
        indexDrop = Array.from(parent.childNodes).indexOf(e.target)
        if(indexDrag < indexDrop){
            e.target.after(dragger);
        }else{
            e.target.before(dragger);
        }
        return
    }
    
}

parent.addEventListener('dragover', (e)=>{
    e.preventDefault()})
house.addEventListener('dragover', (e)=>{
    e.preventDefault()
})



itemList.forEach((el)=>{
    el.addEventListener('dragstart', (e)=>{
        if(!e.target.getAttribute("draggable")){
            e.preventDefault();
        }
        document.querySelector(".text-hint").classList.add("none")
        dragger = e.target
    });
    el.addEventListener('dragover', ((e)=>{
        e.target.classList.add('dragover-el')
    }));
    el.addEventListener('dragleave', ((e)=>{
        e.target.classList.remove('dragover-el')
    }));
})

document.addEventListener('drop', (e)=>{
    document.querySelectorAll('.dragover-el').forEach(el=>{el.classList.remove('dragover-el')});
    e.preventDefault()
    elemList = document.querySelectorAll('.checher')
    if((e.target === parent || e.target.parentNode === parent) && e.target !== dragger){ 
        if(!!dragger && dragger !== house){
            if(itemList.includes(e.target)){
                swaping(e)
            }else if(e.target === parent){
            e.target.appendChild(dragger)
        }
    }
    }
    if(!!dragger && (e.target === house || e.target.parentNode === house) && e.target !== dragger){
        e.preventDefault()
        elemList = document.querySelectorAll('.checher')
        resultArr.splice(resultArr.indexOf(dragger.getAttribute('data-info')),1)
        house.appendChild(dragger)
        swaping(e)
    }})


}