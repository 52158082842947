import React, { useCallback, useEffect, useState } from 'react'
import {useHttp} from '../hooks/http.hook'
import {UserItem} from './components/userItem'

export const AddUser = ()=>{
    const {request, token} = useHttp();
    const[userData,setUserData] = useState(null)
    const[isLoading, setIsloading] = useState(true);
    const getUsers = useCallback(()=>{
        request('/api/user', 'GET', null, {Authorization: token}).then(data=>{setUserData(data); setIsloading(false)})      
    },[request,token])

    useEffect(()=>{
        getUsers()
    },[getUsers])

    return (
        <div className="container p-t">
            <h1 className="page-headline">Добавления студента в группу</h1>
            <div className="collection">
                {isLoading&& <div>Загрузка...</div>}
                {!isLoading&&<UserItem users={userData}/>}
                </div>
        </div>
    )
}