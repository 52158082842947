import React, { useCallback, useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { TurtleExComponent } from '../components/turtle/turtleEx';

export const TurtleEx = ({match})=> { 
    const [exData, setExData] = useState();
    const {request, token, loading} = useHttp();
    const GetData = useCallback(()=>{
        request('/api/turtleex/'+match.params.id, 'GET', null, {Authorization: token})
        .then(data=>{ setExData(<TurtleExComponent lessonId={data._id} props = {data.coords} comands = {data.comands} />)})
    }, [request, token, match.params.id]);

    useEffect(()=>{
        GetData()
    },[GetData])

    return(
        <div>
            <div className="pattern-bg podlozhka"></div>
            {!!exData && !loading && exData}
        </div>
    )
}