import { useCallback } from "react";
import { useHttp } from "./http.hook";

export const usePay = ()=>{
    const {request, token} = useHttp();

    const balanceUpHandler = useCallback((userData, amount)=>{
        if(amount < 1 || typeof(amount) == String){
            window.M.toast({html:"валидация не пройденна"})
            return
        }
        if(userData.contract === undefined){
            window.M.toast({html:"нет номера договора"})
            return
        }
        request('/api/auth/pay-link-gen', 'POST', {amount: amount, contract: userData.contract, email: userData.email})
            .then(data=>{
                document.location.href = data.formUrl
            })
    },[request])

    const Pay = useCallback ((amount)=>{
        request('/api/user/profile', 'GET' ,null, {Authorization: token})
        .then(data => {
            balanceUpHandler(data, amount)
        })
    },[request, token, balanceUpHandler])
  
    
    return Pay
}