import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context/auth.context";
import { useHttp } from "../../../../hooks/http.hook";
import { Loading } from "../../../components/mini/loading";

const RegForm = ({quizData}) => {
    const [formData, setFormData] = useState({
        studentName: "",
        parentName: "",
        email: "",
        password: ""
    });

    const history = useHistory()

    const auth = useContext(AuthContext);

    const changeHandler = ({target}) => {
        setFormData({...formData, [target.name]: target.value})
    }

    const {request, loading} = useHttp();

    const submitHandler = async (evt) => {
        evt.preventDefault();
        const body = {
            email: formData.email,
            userName: formData.studentName,
            userSurname: " ",
            password: formData.password,
            quizData: {
                age: quizData.age,
                favorite: quizData.favorite,
                exp: quizData.exp
            },
            parent: formData.parentName
        };

        request('/api/auth/register-by-start', 'POST', body)
            .then(async () => {
                const authData = await request('/api/auth/login', 'POST',{email: formData.email, password: formData.password});
                auth.login(authData.token, authData.name)
                if(quizData?.age === "От 7 до 9" || quizData?.age === "от 9 до 11"){
                    return history.push({
                        pathname: "/thanks-page",
                        state: {lesson:'60532086942b8fb5fb8b47e8', course:"5fc77b10313b6538fcadd743"}
                    })  
                };
                
                history.push({
                    pathname: "/thanks-page",
                    state: {lesson:'606166fc61ffb7d119c9ee3d', course:"6048a000d2bff7a4ab4cde99"}
                })            
                    
            }).catch((e) => {
                window.M.toast({html: e});
            })      
    }
    
    return(
        <div className="reg-form__wrap start_wrap">
            <div className="start__container">
                <h2 className="quiz__headline">Регистрация</h2>
                <p className="reg-form__desc">
                    <b>1 минута</b> и вы получите бесплатный доступ к курсам YesCoding
                </p>
                <form className="reg-form" onSubmit={submitHandler} onChange={changeHandler}>
                    <input required={true} className="reg-form__input" name="studentName" placeholder="Имя студента" type="name"/>
                    <input className="reg-form__input" name="parentName" placeholder="Имя родителя" type="name"/>
                    <input required={true} className="reg-form__input" name="email" placeholder="email родителя" type="email"/>
                    <input required={true} className="reg-form__input" name="password" placeholder="придумайте пароль" type="password"/>
                    <div className="loading-container">
                        {loading ?
                            <Loading top={-50} left={-90} />  :
                            <button className="reg-form__submit" type="submit">Регистрация</button>
                        }
                    </div>
                        <p className="confirm-poicy">
                        Нажимая 'Регистрация', 
                        вы соглашаетесь на <a href="https://yescoding.ru/personal-data" target="_blank" rel="noopener noreferrer" className="policy-link">
                                обработку персональных данных
                            </a>
                        </p>
                    
                </form>
            </div>
        </div>
    )
}

export default RegForm;