import React, { useCallback, useEffect, useRef } from "react";
import {Dnd} from './services/dnd';
import {ctxController} from './services/ctxController';
import { useHttp } from "../../../hooks/http.hook";

export const TurtleExComponent = data => { 
    const ctxTurtle = useRef();
    const {request, token} = useHttp()

    const AccessHandler = useCallback(() =>{
        request('http://localhost:3000/api/user/turtle/complite/'+data.lessonId,'POST', null, {Authorization: token}).then((data)=>{
            window.M.toast({html: data.message})
        })
        document.getElementById(data.lessonId).classList.add("turtle--complite")
    },[data.lessonId,request,token])

    useEffect(()=>{
        const exportData = {...data}
        exportData.AccessHandler = AccessHandler

        ctxTurtle.current = ctxController(exportData)
        Dnd()
    },[data, AccessHandler ])
    
    const clickHandler = ()=>{
        ctxTurtle.current.Start()
    }

    const loadHandler = ()=>{
        ctxTurtle.current.doClear()
    }

return(
    <div className="main-container">
        <div className="main-turtle__wrap">
            <div className="comand">
                <div className="container-turtle" data-task="1">
                    <div className="challenge-area" id="parent">
                        <div className="text-hint">Перетащите сюда команды для выполнения</div>
                    </div>
                    <div className="challenge-area" id="house" draggable="false">
                        {data.comands !== undefined && data.comands.map((el, i)=>{
                            if(el.includes("num")){
                                return <div key={i} className={"checher "+el} data-info={el[el.length -1]}></div>
                            }
                            return <div key={i} className={"checher "+el} data-info={el}></div>
                        })}
                    </div>
                </div>
                <div className="lover-line">
                    <p className="hint" onClick={clickHandler}>нажмите для проверки </p>

                </div>
            </div>
            <canvas>
                Упс, не работает... Смените браузер.
            </canvas>
        </div>
        <img id="leaves" srcSet="/img/turtleEx/leaves.png" onLoad={loadHandler} width="0" height="0" alt="" />
        <img id="golang" srcSet="/img/turtleEx/pack_edu.png" onLoad={loadHandler} width="0" height="0" alt="" />
        <img id="cactus" srcSet="/img/turtleEx/cactus.png" onLoad={loadHandler}  width="0" height="0" alt="" />
    </div>
)}