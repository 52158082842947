import React from 'react';
import { NavLink } from 'react-router-dom';

export const AchivmentPopUp = ({title, description, image, closeFunc}) =>{
    return(
        <div className="achivment-popup">
            <div className="achivment-wrap">
                <div className="new-achiv-alert__container">
                    <img srcSet="/img/lightning.jpg" alt="lightning" className="light-image"/>
                    <p className="new-achiv-alert">Новая награда</p>
                    <img srcSet="/img/lightning.jpg" alt="lightning" className="light-image"/>
                </div>
                <h2 className="achivment__title">{title}</h2>
                <img srcSet={image} className="achivment__image" alt="картинка"/>
                <p className="achivment__description">{description}</p>
                <div className="achivment__btn" onClick={closeFunc}>Супер!</div>
                <p className="achivment__seemore">Список наград можно посмотреть в <NavLink to="/profile">личном кабинете</NavLink></p>
            </div>
        </div>
    )
}