import React, { useState } from 'react'
import { Lessons } from '../lessons';

export const AddLesson = ()=>{

    const [task,setTask] = useState({name:"",task:""});
    const [form,setForm] = useState({
        name:""
    })

    const descChangeHandler = evt =>{
        setTask({...task, [evt.target.name] : evt.target.value});
    }

    const changeHandler = evt => {
        setForm({...form, [evt.target.name]: evt.target.value})
    }

    const createGroup = ()=>{
        Lessons.createGroup(form, task);
    }


    return(
        <div>
            <div className="row">
                <div className="col s12">
                    <div className="groups-line">
                        <div className="input-field">
                            <input id="title" name="title" type="text" required onChange={changeHandler}/>
                            <label htmlFor="title">Название Урока*</label>
                        </div>
                        <div className="input-field">
                            <input id="name" name="name" type="text"  onChange={descChangeHandler}/>
                            <label htmlFor="name">Название задания</label>
                        </div>
                        <div className="input-field task-aria">
                            <textarea id="task" name="task" className="materialize-textarea" onChange={descChangeHandler}></textarea>
                            <label htmlFor="task">Задание</label>
                        </div>
                        <div className="">
                            <button className="btn waves-effect waves-light" onClick={createGroup}>Добавить урок</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}