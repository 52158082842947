import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowIcon } from './arrowIcon';

export const GoBackSider = ({path=false}) => {

    const history = useHistory();
    const goBack = ()=>{
        if(!path) {
            return history.goBack()
        }
        history.push(path)
    }

    return(
        <div className="side-back__btn" onClick={goBack}><ArrowIcon /></div>
    )
}