import React, {useCallback} from "react"
import { useHttp } from "../../../hooks/http.hook";
import { DeleteBtn } from "./deleteBtn";

export const GiveAdmAndTeachBtn = (user)=>{

    const {request} = useHttp();
    const token = JSON.parse(localStorage.getItem("userData")).token;

    const giveTeacher = useCallback(async(e)=>{
        const body = {
            isTeacher : true
        }
        await request('/api/user/perms/'+e.target.id, 'PATCH', body, {Authorization: token}).then(
            window.M.toast({html: "Пользователь теперь учитель"}))
    },[request,token])

    const giveAdmin = useCallback(async(e)=>{
        const body = {
            isAdmin: true
        }
        await request('/api/user/perms/'+e.target.id, 'PATCH', body, {Authorization: token}).then(
            window.M.toast({html: "Админка добавлена"}))

    },[request,token])

    const deleteHandler = (e)=>{
        document.getElementById(user._id+"1").remove()
    }

    return(
        <div className="user-item__btns">
            <div className="col s1"><button id={user._id} className="btn blue"  onClick={giveTeacher}>Teach</button></div>
            <div className="col s1"><button id={user._id} className="btn yellow waves-effect waves-light" onClick={giveAdmin}>Adm</button></div>
            <div className="col s1" onClick={deleteHandler}><DeleteBtn  route='/api/user' id={user._id}/></div>
        </div>
    )
}