import React, { useState } from 'react'
import { OrderPopup } from '../pages/components/order-popup';
export const usePopUp= ()=>{
    const [popup, setPopup] = useState();
    const closePopUp = () =>{
        setPopup();
    }
    const callPopUp = () =>{
        setPopup(<OrderPopup closeFunc={closePopUp}/>)
    }

    return {callPopUp, popup}
}