import React from 'react'
import { NavLink } from 'react-router-dom'
import { ArrowIcon } from '../../components/mini/arrowIcon'
import { DeleteBtn } from '../../components/mini/deleteBtn';
import { Lessons } from '../lessons';

export const VlessonItem = ({vLessonsData, location, auth, courseId=""}) =>{
    return(
        <div>
            {vLessonsData.map((el,i)=>{
                return (
                    <div key={i} className="user-item vlesson-item">
                        <NavLink className="vlesson-link" to={'/videolessons/?'+el._id + (courseId && `?course=${courseId}`)}>
                            {el.__v !== 99 &&
                                <ArrowIcon />
                            }
                            {el.__v === 99 && <b className="lesson--complite">✔</b>}
                            <span className="vlesson__title">{i+1}. {el.name}</span>
                            
                            <div className="exercise-hint__container">
                                <span className="exercise-hint">
                                    Задание c видео
                                </span>
                            </div>
                        </NavLink> 
                        {auth.isTeacher &&  <div className="add-user-btn" onClick={ Lessons.deleteVideoLesson} vlesso_id={el._id}><DeleteBtn id={location} route={"/api/videolesson/removegroup/"+el._id}/></div>}
                    </div>
                )
            })}
        </div>
    )
}