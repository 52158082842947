import React, { useCallback, useState } from 'react'
import { useHttp } from '../../hooks/http.hook';

export const OrderPopup = ({closeFunc}) => {

    const {request, token} = useHttp();
    const [form, setFormData] = useState({
        phone:"", age:"",program:""
    });
    const changeHanler = ({target}) =>{ 
        if(target.name === "phone"){
        }
        setFormData({...form, [target.name]: target.value})
    }
    

    const formSend = useCallback(() =>{
        request('/api/user/order', 'POST', {phone: form.phone, age: form.age, program: form.program}, {Authorization: token})
            .then(data=>window.M.toast({html: data.message})).then(()=>closeFunc())
    },[form, closeFunc, request, token])
    return(
        <div className="order__popup">
                <span className="close" onClick={closeFunc}></span>
                <h3 className="order__headline">Запись на пробное</h3>
                <p className="order__description">Оставте свой номер телефона и возраст, чтобы мы связались с вами, рассказали, что понадобится для урока и соединили с преподавателем</p>
                <label htmlFor="order-phone">Номер телефона для связи</label>
                <input onChange={changeHanler} id="order-phone" name="phone" type="phone" placeholder="+7 919 999-99-22"/>
                <label htmlFor="order-age">Возраст</label>
                <input onChange={changeHanler} id="order-age" name="age" placeholder="10" type="number" />
                <label htmlFor="order-program">Направление, если вы его уже выбрали. Не выбрали? Ничего страшного! Мы поможем, поле оставте пустым</label>
                <input onChange={changeHanler} id="order-program" name="program" type="text" placeholder="python"/>
                <button className="homing-btn order__btn" onClick={formSend}>Записаться</button>
            </div>
    )
}