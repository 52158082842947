import React, { useState, useCallback, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook';
import { usePay } from '../../hooks/pay.hook';
import { usePopUp } from '../../hooks/popup.hook';
import { GoBackSider } from '../components/mini/gobacksider';

export const Profile = () =>{
    const {request, token, loading} = useHttp();
    const [amount, setAmount] = useState(-1);
    const Pay = usePay()
    const {callPopUp, popup} = usePopUp();

    const [userData, setData] = useState({
        attendanceList: [],
        contract:"",
        fullName: "",
        transactionsList: [],
        cetificates: [],
        achivments: [],
        subscribeStatus: ""
    })

    const setAmountHandler = ({target}) =>{
        setAmount(target.value)
    }

    const setter = useCallback (()=>{
        request('/api/user/profile', 'GET' ,null, {Authorization: token})
        .then(data => {
            if(data.subscribeStatus){
                const dateSub = new Date(data.subscribeStatus) - Date.now()
                data.subscribeStatus = Math.floor(dateSub / 3600 / 24 / 1000)
            }
            setData(data);
        })
    },[request, token])

    useEffect(()=>{
        setter()
    },[setter])
    
    return(
        <div className="container">
            <h1 className="profile-page__headline">{userData.fullName}</h1>
            <div className="price-page__decoration-line profile-line"></div>
            <div className="profile-page__info-grid">
                <div className="info-grid__item info-grid__balance-wrap">
                    <b>Баланс:</b>
                    <span>{userData.balance ? userData.balance : 0} руб</span>
                </div>
                <div className="info-grid__item info-grid__status-wrap">
                    <b>Статус:</b>
                    <span>{userData.status}</span> 
                </div>
                <div className="info-grid__item info-grid__pay-field">
                    <b>Сумма:</b>
                    <input id="amount" type="number" min="0" placeholder="4000" onChange={setAmountHandler}/>
                </div>
                <button className="info-grid__item balance-up" onClick={()=>{Pay(amount)}}>
                    <div>Пополнить баланс</div>
                </button>
                <div className="info-grid__item info-grid__sub-status">
                    <b>Подписка:</b>
                    <span>{userData.subscribeStatus ? userData.subscribeStatus + " дней": "Не активна"}</span> 
                </div>
                <div className="info-grid__item info-grid__atandance">
                    <b>Расписание:</b>
                    <span>{userData.scheduleOfGroup}</span> 
                </div>

                {!userData.subscribeStatus && !loading &&<NavLink to="/price" className="info-grid__item subscribe__btn">
                    <p className="subscribe-btn__cta">Оформить подписку</p>
                    <p className="subscribe-btn__description">
                        Доступ ко всем курсам, индивидуальные консультации и тренажеры
                    </p>
                </NavLink>}

                {!!userData.subscribeStatus && !loading  &&<button onClick={callPopUp} className="info-grid__item subscribe__btn">
                    <p className="subscribe-btn__cta">Занятия с ментором</p>
                    <p className="subscribe-btn__description">
                        Записаться на занятия с преподавателем по видео-связи в группе или индвидуально
                    </p>
                </button>}
            </div>
            <div className="profile__achievements-grid">
                    <div className="fast-desc__item desc__item--white">
                        <span className="desc-item__number">{userData.lessonsCount ? userData.lessonsCount: 0}</span>
                        <span className="desc-item__content">пройденных видео-уроков</span>
                    </div>
                    <div className="fast-desc__item desc__item--white">
                        <span className="desc-item__number">{userData.testsCount ? userData.testsCount: 0}</span>
                        <span className="desc-item__content">пройденных<br/>тестов</span>
                    </div>
                    <div className="fast-desc__item desc__item--white">
                        <span className="desc-item__number">{userData.achivments ? userData.achivments.length : 0}</span>
                        <span className="desc-item__content">полученных наград</span>
                    </div>
                    <div className="fast-desc__item desc__item--white">
                        <span className="desc-item__number">{userData.coursesComplite ? userData.coursesComplite : 0}</span>
                        <span className="desc-item__content">пройденных курсов</span>
                    </div>
                    <div className="fast-desc__item desc__item--white">
                        <span className="desc-item__number">{userData.cetificates ? userData.cetificates.length : 0}</span>
                        <span className="desc-item__content">Полученно сертификатов</span>
                    </div>
            </div>
            <section className="profile__awards">
                <h2 className="profile-page__h2">Награды</h2>
                <div className="awards-list">
                    {userData.achivments.length !== 0 ? userData.achivments.map(el => {
                        return(
                        <AwardInProfile
                        key={el._id}
                        image={el.image} 
                        headline = {el.headline}
                        description = {el.description}/>)
                    }) : "Награды пока не получены"}
                </div>
            </section>
            <section className="profile__certificates">
                <h2 className="profile-page__h2">Сертификаты</h2>
                <div className="certificates-list">
                   {userData.cetificates.length !== 0 ? userData.cetificates.map(el=><div style={{backgroundImage: el.image}}></div>) : "Сертификаты пока не получены"}
                </div>
            </section>
            <section className="activity">
                <h2 className="profile-page__h2">Уроки и списания</h2>
                <div className="activity__table">
                    {userData.transactionsList.length !== 0 &&
                        userData.transactionsList.map(el=>{
                            return <ActivityTable key={el._id} title={el.purpose} date={el.date} sum={el.sum}/>
                        })
                    }
                    {userData.transactionsList.length === 0 && "Транзакций пока не проходило"}
                    
                </div>
            </section>
            {popup}
            <GoBackSider />
        </div>
    )
}

const ActivityTable = ({title, date, sum}) => {
    return(
        <div className="active-table__item">
            <span className="active-table-item__title">{title}</span>
            <span className="active-table-item__date">{date.slice(0,10)}</span>
            <span className="active-table-item__sum">{sum} руб</span>
        </div>
    )
}

const AwardInProfile = ({image, headline, description}) => {
    return(
        <div className="awards-list__item">
            <img srcSet={image} alt={headline}/>
            <h3>{headline}</h3>
            <p>{description}</p>
        </div>
    )
}