import React, {  useState, useEffect, useContext, useRef } from "react"
import { DeleteBtn } from "./mini/deleteBtn"
import { AuthContext } from "../../context/auth.context"
import { Tasks } from "../task"
export const TaskItems = data => {

    const [lessons,setLessons] = useState(null)
    const auth = useContext(AuthContext);
    const deleteRef = useRef();
    useEffect(()=>{
        setLessons(<TaskItem name={data.name} task={data.task}/>)
    },[data])  
    
    const imageClickHandler = evt =>{
        if(evt.target.tagName ==="IMG"){
            evt.target.parentNode.classList.toggle("big-image");
        }if(evt.target.tagName === "DIV"){
            evt.target.classList.toggle("big-image");
        }
    }

    const imageCheck = ()=>{
        switch (data.imageSrc.slice(data.imageSrc.length-4).toLowerCase()) {
            case ".jpg":
                return true;
                
            case ".png":
                return true;
                
            default:
                return false
                
        }
    }


    return (
        <div className="collection-item task-item" ref={deleteRef}>
                <div className="task-line">
                    <div className="task-line__order"><span>Задание {data.order}. </span></div>
                    <div className="task-line__title">
                        {data.title !== "undefined" && <a href={data.title} target="_blank" rel="noopener noreferrer">Ссылка</a>}
                    </div>
                    {lessons}
                    {!!data.imageSrc && <>
                    {!imageCheck() ?
                        <div className="file_download-btn"> <a href={`${document.location.protocol}/${data.imageSrc}`} download >файл</a> </div> :                 
                        <div className="task-line__image" onClick={imageClickHandler}><img alt="" srcSet={`${document.location.protocol}/${data.imageSrc}`} /></div>}
                    </>}
                    {auth.isTeacher && 
                    <div onClick={Tasks.deleteHandler} className="task__add-user-btn" >
                        <DeleteBtn id={data._id} route="/api/task" itemToDelete={deleteRef}/>
                    </div>}
                </div>
        </div>
    )
}

const TaskItem = tasks => {
    return (
        <div className="task_element">
            <div><i className="task-tittle">"{tasks.name}"</i></div>
            <div>{tasks.task}</div>
        </div>
    )
}
