import React, { useEffect, useState, useCallback, useContext } from 'react'
import {useHttp} from '../hooks/http.hook'
import { TaskItems } from './components/taskItem';
import { AuthContext } from '../context/auth.context';
import { GoBackSider } from './components/mini/gobacksider';
export const Tasks = ()=>{
    const {request, token, loading} = useHttp();
    const [lessonData,setLessonsData] = useState(null)
    let location = document.location.search.slice(1);
    const [task,setTask] = useState({name:"",task:""})
    const [form,setForm] = useState({
        name:""
    })
    const [file,setFile] = useState(null)

    const auth = useContext(AuthContext)


    const changeHandler = evt => {
        setForm({...form, [evt.target.name]: evt.target.value})
    }

    const descChangeHandler = evt =>{
        setTask({...task, [evt.target.name] : evt.target.value})
    }

    const render = useCallback(()=>{
        try{
            request('/api/task/'+location, 'GET',null, {Authorization: token})
            .then(data => {
                setLessonsData(
                    data.map((el,i)=>{
                    return <TaskItems key={i} {...el}/>
                }))
            })
        }catch(e){
            console.log(e)
        }
    },[location,request,token])

    const createTask = useCallback( async()=>{
        const fd = new FormData();

        if(file){
            if(file.size > 8388608){
                window.M.toast({html: "Файл не должен превышать 8мб"})
                return
            }
            fd.append('image',file,file.name)
        }
        fd.append("title", form.title)
        fd.append("name", task.name)
        fd.append("task", task.task)
        
        try {
            const response = await fetch('/api/task/'+location, {method:'POST',body:fd,headers: {Authorization: token}})
            response.json().then(data=>{
                
                setLessonsData(prev=>{
                    return prev.concat(<TaskItems {...data} key={data._id}/>) }
                    )
            })
        }catch(e){
            console.log(e)
        }
    },[file,token,form.title,location,task])

    const fileSelectedHandler = evt =>{
        setFile(evt.target.files[0])
    }

    useEffect(()=>{
        render()
    },[render])

    Tasks.deleteHandler = ({target}) =>{
        setLessonsData(prev=>{
            return prev.filter((el,i)=>{
                return(el.props._id !== target.id)
            })
        })
    }

    return (
        <div className="container p-t">
            <h1 className="page-headline">Задания</h1>
            {auth.isTeacher&& (
            <div className="row">
                <div className="col s12">
                    <div className="groups-line">
                        <div className="input-field">
                            <input id="title" name="title" type="text"  onChange={changeHandler}/>
                            <label htmlFor="title">Полезная ссылка</label>
                        </div>
                        <div className="input-field">
                            <input id="name" name="name" type="text"  onChange={descChangeHandler}/>
                            <label htmlFor="name">Название</label>
                        </div>
                        <div className="input-field task-aria">
                            <textarea id="task" name="task" className="materialize-textarea" onChange={descChangeHandler}></textarea>
                            <label htmlFor="task">Задание</label>
                        </div>
                        <div className="input-field">
                            <div className="btn file-upload">
                                <span className="file-upload">File</span>
                                <input className="file-upload" type="file" name="file" onChange={fileSelectedHandler}/>
                            </div>
                            <div className="file_label">{file ? file.name : "файл не выбран"}</div>
                        </div>
                        <div >
                            <button className="btn waves-effect waves-light" disabled={loading && "disabled"} onClick={createTask}>Добавить урок</button>
                        </div>
                    </div>
                </div>
            </div> )}
            <div className="collection" id="task_collection">{ lessonData && lessonData}</div>
            <GoBackSider />
        </div>
    )
}