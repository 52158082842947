import React, {  useState } from "react";
import { NavLink } from "react-router-dom";
import '../.././style.css';

const Question = ({data, dataSetter}) => {

    const [checkedEl, setCheckedEl] = useState("");

    const changeHandler = ({target}) => {
        setCheckedEl(target.value);
        
    }

    const check = () => {
        dataSetter(data.step, checkedEl);
        setCheckedEl("");
    }

    return (
        <div>
            <h2 className="quiz__headline">{data.question}</h2>
            <div className="question-list__wrap">
                {data.answers.map(el=>{
                    return <div className={checkedEl === el ? "quiz__label quiz__label--checked": "quiz__label"}key={el}>
                        <label  htmlFor={el}> 
                            <span>{el}</span>
                        </label> 
                            <input className="quiz__radio"
                                onChange={changeHandler} 
                                name={data.question} 
                                type="radio" 
                                id={el}  
                                value={el}
                                checked={checkedEl === el}
                                />
                    </div>
                    })}
            </div>
            {
                checkedEl.length > 0 && 
                <NavLink onClick={check} className="question__next-btn" to={'/begin-quiz/'+data.nextLink} >
                        дальше
                </NavLink>
            }            
            
        </div>
    )
}

export default Question;