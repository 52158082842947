import React, { useState, useEffect, useCallback, useContext } from 'react'
import {useHttp} from '../../hooks/http.hook'
import {GroupItem} from './components/groupItem'
import {Loading} from '../components/mini/loading'
import { AuthContext } from '../../context/auth.context';
import { usePopUp } from '../../hooks/popup.hook';

export const Groups = ()=>{
    const { request, loading } = useHttp();
    const { popup, callPopUp } = usePopUp();
    
    const [teachers, setTeachers] = useState([]);
    const [groups, setGroups] = useState();
    
    const [form,setForm] = useState({
        name:"",teacherId:"",schedule:""
    })

    const auth = useContext(AuthContext)

    const createGroup = ()=>{ 
        request('/api/groups', 'POST',{...form}).then(()=>{
            window.M.toast({html: "Группа создана"})
            renderGroup()
        })
        document.querySelectorAll('input').forEach(e=>{
            e.value = ""
        }) 
    }

    const GetTeachers = useCallback(() => {
        if(auth.isAdmin){
            request('/api/user/teachers', 'GET',null).then(data => {
                if(data!==undefined){
                setTeachers(data.map(el => {
                    return(
                        <option key={el._id} value={el._id}>{el.userName} {el.userSurname}</option>
                    )
                }))
            }
            })
        }
    },[request, auth.isAdmin])
      
    
    useEffect(()=>{
        GetTeachers()
    },[GetTeachers])

    const changeHandler = evt => {
        setForm({...form, [evt.target.name]: evt.target.value})
    }
    
    const renderGroup = useCallback(()=>{
            request('/api/groups', 'GET',null)
            .then((data) => {
                setGroups(data)
            });    
    },[request]
    )
    useEffect(()=>{
        renderGroup()
        localStorage.removeItem('pagesHeightCash')
    },[renderGroup])

    return (
    <div className="pattern-bg">
        <div className="container pattern-bg">
            <h1 className="page-headline headline--shadow" >Группы</h1>
            {auth.isAdmin&& (<div className="row">
            <div className="col s12">
            <div className="groups-line">
                <div className="input-field">
                    <input id="name" name="name" type="text" className="group-creating"  onChange={changeHandler}/>
                    <label htmlFor="name">Название </label>
                </div>
                
                <div className="input-field">
                    <select name="teacherId" onChange={changeHandler}>
                        <option defaultValue value="">   ——  ——</option>
                        {teachers}
                    </select>
                </div>
                <div className="input-field">
                    <input id="schedule" name="schedule" type="text" className="group-creating" onChange={changeHandler}/>
                    <label htmlFor="schedule">Время занятий</label>
                </div>
                <div className="group-create__btn-container">
                    <button className="btn waves-effect waves-light" onClick={createGroup}>Создать группу</button>
                </div>
                
            </div>
            </div>
            </div>)}
            
            <div className="collection group-collection">
                {loading && <div className="group__loading"><Loading /></div>}
                {!loading && groups && <GroupItem groups={groups} perms={auth}/>}
                {!loading && groups !== undefined && groups.length === 0 && 
                <div className="group__without-group">
                    <h2 className="without-group__headline">
                        Вы ещё не состоите не в одной из групп!
                    </h2>
                    <p className="without-group__soon">
                        Если вы уже занимаетесь у нас, то вас <b>скоро присоедят к группе!</b>
                    </p>
                    <p className="without-come-to-us">
                        Если вы ещё не заниметесь у нас, вы можете попробовать, записавшись на пробное занятие
                    </p>
                    <div>
                        <button className="homing-btn without-btn" onClick={callPopUp}>Записаться на пробное</button>
                    </div>
                </div>}
            </div>
        </div>
        {popup}
    </div>
    )
}