import React, { useCallback, useEffect, useState } from 'react'
import { useHttp } from '../../hooks/http.hook';
import { GoBackSider } from '../components/mini/gobacksider';

export const AddVlessons = () => {

    const token = JSON.parse(localStorage.getItem("userData"))? JSON.parse(localStorage.getItem("userData")).token : null;
    const {request} = useHttp();
    const [vLessonsList, setVlessonsList] = useState()
    const location = document.location.search.slice(1);

    const AddHendler = useCallback(({target}) =>{
        request('/api/videolesson/addgroup/'+target.id, 'PATCH', {groupid: location}, {Authorization: token}).then(data=>{
            window.M.toast({html: data.message})
        }).then(()=>{
            setVlessonsList(prev=>{
                return prev.filter(el=>{
                    return el.key !== target.id
                })
            })
        })
    },[request,token, location])
    
    const getData = useCallback(()=>{
        request('/api/videolesson/getvlesson/'+location, 'GET', null, {Authorization: token}).then(data=>{
            setVlessonsList(data.map((el)=>{
                return (<div className="collection-item vlesson-row" key={el._id}>
                        <span>{el.name}</span>
                        <button onClick={AddHendler} id={el._id} className="btn waves-effect waves-light">+</button>
                    </div>)
            }))
        })
    },[request,token, AddHendler, location])



    useEffect(()=>{
        getData()
    },[getData])

    return(
        <div className="p-t container">
            <h1 className="page-headline">Добавить видео урок</h1>
            <div className="collection ">
            {vLessonsList}
            </div>
            <GoBackSider />
        </div>  
    )
}
