import React from "react"
import { NavLink } from "react-router-dom"
import { Updater } from "./updater"
import { DeleteBtn } from "../../components/mini/deleteBtn"
export const GroupItem = ({groups, perms}) => {
    const corHandler = (e)=>{
        const corred = e.target.parentNode.parentNode
        if(corred.classList.contains("group-corr_open")){
            e.target.parentNode.parentNode.classList.remove("group-corr_open")
        }else{
            e.target.parentNode.parentNode.classList.add("group-corr_open")
        }
    }

    let prevName = ""
    let newT = false;
    
    
    
    return (
        <div>
            {groups.map((group, i)=>{
                if(perms.isAdmin){
                    newT = false
                    if(prevName !== group.teacher){
                        newT = true
                    }
                    prevName = group.teacher
                }
                return(
                    <div key={group._id} className="collection-item user-item">
                        {newT&& <h3>{group.teacher}</h3>}
                        <Updater groupId={group._id} {...group}/>
                        <NavLink to={"/lessons/?"+group._id} className="user-link" >
                            <div className="groups-line">
                                <div className="groups-line__item"><b className="group_name">{group.name}</b></div>
                                <div className="groups-line__item">Преподаватель: <b className="group_teacher">{group.teacher}</b></div>
                                <div className="groups-line__item">Время занятий: <b className="group_schedule">{group.schedule}</b></div>
                            </div>
                        </NavLink>
                        {perms.isAdmin && 
                        <div className="add-user-btn">
                            <button className="waves-effect waves-light btn-small" onClick={corHandler}>Ред</button>
                            <NavLink to={"/adduser/?"+group._id} className="waves-effect waves-light btn-small"><b>+</b></NavLink>                          
                            <DeleteBtn id={group._id} route="/api/groups" itemToDelete={null} />
                        </div>}
                    </div>
                )
            })
            }
        </div>
    )
}