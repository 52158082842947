import React from "react"

export const Loading = ({top=0, left=0}) =>{
    return(
        <div className="loading__container" style={{top: top, left: left}}>
            <div className="loadingio-spinner-bean-eater-prltkzhz4qp"><div className="ldio-p5ouf7axk5">
            <div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
            </div></div>
        </div>
    )
}