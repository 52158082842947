import React from "react"
import { GiveAdmAndTeachBtn } from "./mini/giveAdmAndTeachBtn";
import { GroupManagementBtns } from "./mini/groupManagementBtns";

export const UserItem = ({users, perms}) => {
    return (
        <div>
        {users.map((userData, i)=>{
            return(
            <div className="collection-item user-item__row" key={i} id={userData._id+"1"}>
                <div className="col s2 ">{userData.userName} {userData.userSurname}</div>
                <div className="col s3">{userData.email}</div>
                {perms&&<div className="col s3"><b>{userData._id}</b></div>}
                {!perms&&<GroupManagementBtns {...userData} />}
                {perms&&<GiveAdmAndTeachBtn {...userData}/>}
            </div>
        )})}
        </div>
       
    )
}
