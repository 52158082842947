import {createContext} from 'react'

function noop() {

}

export const AuthContext = createContext({
    token:null,
    userName:null,
    login:noop,
    logout: noop,
    isAuntificated: false,
    isAdmin: false,
    isTeacher: false
})