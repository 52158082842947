import React, { useState, useEffect, useCallback } from 'react'
import { useHttp } from '../../hooks/http.hook';
import { usePay } from '../../hooks/pay.hook';
import {Loading} from '../components/mini/loading';
import {NavLink} from 'react-router-dom';
import { GoBackSider } from '../components/mini/gobacksider';

export const PricePage = ()=>{

const Pay = usePay();
const {request, token} = useHttp();
const PayHandler = (amount)=>{
    Pay(amount)
}
const [subModal, setSubModal] = useState() 

const updateSubscribe = useCallback((subPrice, subPeriod) =>{
    setSubModal(<SubSuccessModal loading={true}/>)
    request('/api/user/subscibe/update', 'POST', {subPrice: subPrice, subPeriod: subPeriod},{Authorization: token})
        .then(()=>{
            setSubModal(<SubSuccessModal loading={false}/>)
        })
},[token, request])

const closeModal = useCallback(evt =>{
    if(evt.key === "Escape"){
        evt.preventDefault();
        closeModaldo();
    }
},[])

const closeModaldo = () =>{
    setSubModal('');
}

useEffect(() => {
    window.addEventListener('keydown', closeModal);

    return () => {
      window.removeEventListener('keydown', closeModal);
    };
  }, [closeModal]);

const subPlanChoiseHandler = (subPrice, subPeriod,e)=>{
    e.target.classList.add("none");
   
    
    request('/api/user/profile', 'GET', null, {Authorization: token})
        .then(data=>{
            if(data.balance >= subPrice){
                setSubModal(<SubUpdateModal closeModal={closeModaldo} subPeriod={subPeriod} subPrice={subPrice} updateSubscribe={updateSubscribe}/>)
            }else{
                PayHandler(subPrice - data.balance)
            }
        })
}

    return(
        <div>
            <div className="container price-page__top-line">
                <div className="top-line__item">
                    <h1 className="price-page__headline">Оформить подписку</h1>
                    <div className="price-page__decoration-line"></div>
                    <div className="fast-desc">
                        <div className="fast-desc__item">
                            <span className="desc-item__number">42</span>
                            <span className="desc-item__content">видео-уроков с тестами</span>
                        </div>
                        <div className="fast-desc__item">
                            <span className="desc-item__number">30</span>
                            <span className="desc-item__content">заданий тренажёров</span>
                        </div>
                    </div>
                </div>

                <div className="top-line__item top-line__description">
                    <img className="top-line__image" srcSet="./img/price-page-courses1.png" alt="Видео-уроки по программированию дешего"/>
                    <div>
                    <p>
                        Содержательные и понятные видео-уроки с теорией и практикой.
                    </p>
                    <ul>
                        <li>Видео-уроки "без воды"</li>
                        <li>Закрепляющие тесты</li>
                        <li>Сетрификат об окончании</li>
                    </ul>
                    </div>
                </div>
            </div>
            <div className="pattern-bg">
                <div className="container">
                    <div className="price__plans-line">
                        <div className="plans-line__item">
                            <h3 className="line-item__h3">Месяц</h3>
                            <p className="line-item__price">690 руб/<span className="price__small-desc">месяц</span></p>
                            <ul className="line-item__description-list">
                                <li>Доступ ко всем тренажёрам и курсам на месяц.</li>
                                <li>Сертификаты за прохождение курсов</li>
                                <li>Полноценные курсы по видео-урокам и закрепляющим тестам</li>
                            </ul>
                            {!subModal && <div onClick={(e)=>{subPlanChoiseHandler(690, 30, e)}}  className="line-item__button">Выбрать</div>}
                        </div>
                        <div className="plans-line__item mentor-item">
                            <h3 className="line-item__h3">Уроки с ментором</h3>
                            <p className="line-item__price">300 руб*/<span className="price__small-desc">занятие</span></p>
                            <ul className="line-item__description-list">
                                <li>Доступ ко всем тренажёрам и курсам.</li>
                                <li>Пробное занятие</li>
                                <li>Создать игру или сайт</li>
                                <li>Получить обратную связь от преподавателя</li>
                                <li>Поддержка и консультация</li>
                                <li>Демонстрация экрана</li>
                                <li>Маленькие группы, для максимальной эффективности</li>
                            </ul>
                            <a href="https://yescoding.ru/?utm_source=homing" target="_blank" rel="noopener noreferrer" className="line-item__button">Подробнее</a>
                            <p className="price-page__disclamer">*цена только за первое занятие</p>
                        </div>
                        <div className="plans-line__item">
                            <h3 className="line-item__h3">Год</h3>
                            <p className="line-item__price">4900 руб/<span className="price__small-desc">месяц</span></p>
                            <ul className="line-item__description-list">
                                <li>Доступ ко всем тренажёрам и курсам на год.</li>
                                <li>Полноценные курсы по видео-урокам и закрепляющим тестам</li>
                                <li>Выгодная цена по сравнению с оплатой за месяц</li>
                                <li>Сертификаты за прохождение курсов</li>
                            </ul>
                            {!subModal &&<div className="line-item__button" onClick={(e)=>{e.target.classList.add("none");subPlanChoiseHandler(4900, 360,e)}}>Выбрать</div>}
                        </div>
                        <div className="price-page__best-podlozhka"></div>
                    </div>
                </div>
            </div>
            {subModal}
            <GoBackSider />
        </div>
    )
}

const SubUpdateModal = ({subPrice, subPeriod,updateSubscribe, closeModal}) =>{
    return (
        <div className="modal-podlozhka">
            <div className="modal sub-update-modal">
                <h2>Оформление подписки</h2>
                <p>Вы хотите списать со своего счета {subPrice} рублей для активации подписки на {subPeriod} дней</p>
                <button className="homing-btn" onClick={()=>updateSubscribe(subPrice, subPeriod)}>Да, всё верно!</button>
                <p onClick={closeModal} className="homing-small-blue-text">Нет, это ошибка!</p>
            </div>
        </div>
    )
}

const SubSuccessModal = ({loading}) =>{

    return(
        <div className="modal-podlozhka">
            <div className="modal sub-update-modal"> 
            {loading && <div className="modal-loading"><Loading className=""/></div>}
            {!loading && <>
                <h2>Подписка активирована</h2>
                <p>Подписка активирована! Теперь можно перейти в курсы и продолжить занятия!</p>
                <NavLink to="/courses" className="homing-btn">Вперед к знаниям</NavLink>
                </>}
            </div>
        </div>
    )
}
