import React, { useCallback, useEffect, useState } from 'react';
import { NavLink,} from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import { Loading } from '../components/mini/loading';


export const TurtlePage = ({children})=> {
    const {request, token, loading} = useHttp();
    const [turtleExList, setTurtleExList] = useState([]);

    const GetDataList = useCallback(()=>{
        request('/api/turtleex/', 'GET', null, {Authorization: token})
        .then(data=>{setTurtleExList(
            data.map(el=>{
                const compliteClass = el.complite? "turtle--complite" : ""
                return <li key={el._id}  id={el._id}> <NavLink className={compliteClass} activeClassName="lessons-link__current" to={"/turtle/"+el._id}>Задание {el.order}</NavLink></li>
            })
        )})
    }, [request, token]);
    
    useEffect(()=>{
        GetDataList()
    },[GetDataList])

    return (
        <div className="container turtle-page__wrap">
            <div className="pattern-bg podlozhka"></div>
            <h1 className="price-page__headline">Тренажер</h1>
            <div className="price-page__decoration-line"></div>
            <div className="turtle-page-wrap">
                <div className="turtle-left">
                {loading && <Loading />}
                {!!children && children}

                </div>
            
                <div className="turtle-page__lessons-link">
                    <ul className="lessons-link__list">
                        <li><NavLink to="/turtle" >Введение</NavLink></li>
                        {turtleExList}
                    </ul>
                </div>
            </div>
        </div>
    )
}