import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import './style.css';

const StartPage = () => {

    const location = useLocation()

    useEffect(()=>{
        if(!location.search.length > 0){
            return
        }

        const splitetArray = location.search.slice(1).split('&');
        splitetArray.map(el=>{
            Cookies.set(el.split("=")[0], el.split("=")[1]);
            return {[el.split("=")[0]]: el.split("=")[1]}
        })
    },[location])

    return(
        <div className="start_wrap">
            <header className="start__header">
                <div className="start__container start-header__wrap">
                    <div className="start-header__decor">
                        <img className="thanks-page__img" alt="Спасибо за регистрацию" src="/img/hamster_hello.png" />
                    </div>
                    <h1>Homing — будущее для каждого ребенка</h1>
                    <p className="start-header__description">
                        Курсы программирования, для школьников. Бесплатно, полезно и перспективно.
                    </p>
                    <NavLink to="/begin-quiz/0" className="start__btn start__begin-btn">
                        начать
                    </NavLink>
                    <NavLink to='/' className="start__btn start__login-btn">
                        уже есть аккаунт
                    </NavLink>
                </div>
            </header>
            <section className="about">
                <div className="start__container">
                    <div className="about__decor"></div>
                    <h2 className="start__headline">Что такое Хоминг</h2>
                    <p className="about__description">Хоминг — это проект от YesCoding<br/><br/>
                    Хоминг возможнсть школьникам изучать разные сферы ИТ по коротким видео-урокам и тестам</p>
                </div>
                <div className="about__bottom-decor"></div>
            </section>
            <section className="why">
                <div className="start__container">
                    <div className="why__decor">
                        <img className="thanks-page__img" alt="Спасибо за регистрацию" src="/img/thinking_hamster.png" />

                    </div>
                    <h2 className="start__headline why__headline">Зачем детям программирование</h2>
                    <ul className="why__advantage-list">
                        <li className="advantage-list__item">
                            <div className="advantage__decor">
                            </div>
                            <div className="advantage__content">
                                <h3 className="advantage-content__headline">Навыки 21 века</h3>
                                <p className="advantage-content__descriptionß">
                                    Программирование является самым востребованным навыком за последние 5 лет. И по прогнозам специалистов, знание языков программирования еще долго останется востребованным.
                                </p>
                            </div>
                        </li>
                        <li className="advantage-list__item">
                            <div className="advantage__decor"></div>
                            <div className="advantage__content">
                                <h3 className="advantage-content__headline">И в школе полезно</h3>
                                <p className="advantage-content__descriptionß">
                                    Кодинг развивает вычислительное мышление. Помогает решать задачи, принимать решения и анализировать их.
                                    Очень полезно в школьных предметах.
                                </p>
                            </div>
                        </li>
                        <li className="advantage-list__item">
                            <div className="advantage__decor"></div>
                            <div className="advantage__content">
                                <h3 className="advantage-content__headline">Обучение мотивированое результатами</h3>
                                <p className="advantage-content__descriptionß">
                                    Создавая свою игру, ребенок всегда останется мотивированым. Он хочет учиться, задавать вопросы и искать на них ответы.
                                </p>
                            </div>
                        </li>
                    </ul>
                    <NavLink to="/begin-quiz/0" className="start__btn start__begin-btn">
                        начать
                    </NavLink>
                </div>
                <div className="why__bottom-decor"></div>
            </section>
            <section className="mentors">
                <div className="start__container">
                    <div className="mentors__decor"></div>
                    <h2 className="start__headline">Крутые Менторы из YesCoding</h2>
                    <p className="about__description">Если захотите большего погружения вы всегда сможете обратиться к менторам из YesCoding. Они помогут достигнуть максимальной эффективности в обучении и стать настоящими разработчиками.</p>
                    <a href="https://yescoding.ru?utm_source=homing" rel="noopener noreferrer" target="_blank" className="start__btn mentors__btn">
                        подробнее
                    </a>
                </div>
                <div className="about__bottom-decor"></div>
            </section>
            <section className="call-t-a">
                <div className="start__container">
                    <h2 className="start__headline why__headline">Получайте IT навыки вместе с Homing</h2>
                    <NavLink to="/begin-quiz/0" className="start__btn c-t-a__btn start__begin-btn">
                            начать
                    </NavLink>
                </div>
            </section>
            <footer className="start__footer">
                <div className="start__container">
                    <h3 className="footer__headline">О нас</h3>
                    <ul className="footer__links-list">
                        <li className="footer-list__item"> <a href="https://yescoding.ru" rel="noopener noreferrer" target="_blank">YesCoding</a></li>
                        <li className="footer-list__item"><a href="https://yescompany.ru" rel="noopener noreferrer" target="_blank">yes — английский</a></li>
                        <li className="footer-list__item">mathYesha (скоро)</li>
                        <li className="footer-list__item"><a href="https://yescoding.ru/personal-data" target="_blank" rel="noopener noreferrer"  >Пользовательское соглашение</a></li>
                    </ul>
                    <h3 className="footer__headline">Контакты</h3>
                    <ul className="footer__links-list">
                        <li className="footer-list__item">coding@yescoding.ru</li>
                        <li className="footer-list__item">homing@yescoding.ru</li>
                        <li className="footer-list__item"><a href="https://t.me/yescoding" target="_blank" rel="noopener noreferrer">t.me/yescoding</a></li>
                        <li className="footer-list__item"><a href="https://instagram.com/yes.coding" target="_blank" rel="noopener noreferrer"> @yes.coding </a></li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

export default StartPage;