import React from 'react'

export const TransactionListItem = ({transactionList})=>{

    return (
        <div>
             <div className="transaction-list__item table-title">
                    <span className="trans-list-item__order-id">Номер оплаты</span>
                    <span className="trans-list-item__order-date">Даты</span>
                    <span className="trans-list-item__order-sum">Сумма</span>
                    <span className="trans-list-item__order-user">Имя</span>
                </div>
            {transactionList !== null && transactionList.map(el =>{
                return <div key={el._id} className="transaction-list__item">
                    <span className="trans-list-item__order-id">№{el.orderId}</span>
                    <span className="trans-list-item__order-date">{el.date.replace("T", " ").slice(0,-8)}</span>
                    <span className="trans-list-item__order-sum">{el.sum} руб</span>
                    <span className="trans-list-item__order-user">{el.user} {el.userSurname}</span>
                </div>
            })}
        </div>
    )
    
}