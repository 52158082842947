import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../../context/auth.context';
import { usePopUp } from '../../../hooks/popup.hook';

export const CoursesElem = (data) =>{
    const auth = useContext(AuthContext);
    return(
        <div className="course-elem__container">
            {(!!data.disabled || !!data.awaiting )&& <CourseElemInner {...data}/>}
            {!data.disabled && !data.awaiting  && <NavLink to={"/videocourse/?"+data._id}>
                <CourseElemInner {...data}/>
            </NavLink>
            }
            {auth.isAdmin && <NavLink to={"/add_to_course/?"+data._id} className="btn course__add-user-btn">+</NavLink>}
        </div>
    )
}

const CourseElemInner = (data) => {
   
    const {callPopUp, popup} = usePopUp()
    const disabledClass = !!data.disabled || !!data.awaiting ? "course__item--disabled" : "";
return(
    <div className="course-elem__container">
        <div className={"course__item "+disabledClass} style={{backgroundImage: `url(${data.imageAvatar})`}}>
            <div className="description_container">
                <ul className="skills-list">
                    {
                        !!data.skills && data.skills.map((el, i)=>{
                        return(<li key={i} className="skills-list__item">{el}</li>)
                        })
                    }
                </ul>
                <span className="course_programm">Программа</span>
                <p className="course_name">{data.name}</p>
                <span className="course_programm">{!!data.description && data.description }</span>
                <span className="course_age">{(!!data.age && data.age )|| "7+"}</span>
            </div>
        </div>
        {!!data.disabled && !data.awaiting && <Link to="/price" className="course__get-button">Получить</Link>}
        {!!data.awaiting && <div className="course__get-button course__awaiting" onClick={callPopUp}><span role="img" aria-label="ноутбук">💻 </span>Пока в разработке, но доступен в формате:<br/>"С преподавателем" </div>}
        {popup}
        </div>
)
}