import React, {useCallback} from "react"
import { useHttp } from "../../../hooks/http.hook";

export const GroupManagementBtns = (user)=>{
    
    const {request} = useHttp();
    const token = JSON.parse(localStorage.getItem("userData")).token;
    let location = document.location.search.slice(1);
    const addStudent = useCallback(async(e)=>{
        const body = {
            id: e.target.id
        }

        await request('/api/user/'+location, 'PATCH', body, {Authorization: token}).then(
            window.M.toast({html: "Студент добавлен"})
        )
    },[request,token,location])

    const removeUserGroup = useCallback(async(e)=>{
        const body = {
            id: e.target.id
        }

        await request('/api/user', 'PUT', body, {Authorization: token}).then(
            window.M.toast({html: "Пользователь удален из групп"})
        )
    },[request,token])
    return(
        <div className="user-item__btns">
            <div className="col s3"><button id={user._id} className="btn blue"  onClick={addStudent}>Добавить студентов</button></div>
            <div className="col s3"><button id={user._id} className="btn yellow waves-effect waves-light" onClick={removeUserGroup}>Удалить из групп</button></div>
        </div>
    )
}