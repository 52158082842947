import React, { useCallback, useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http.hook';

export const AddToCourse = () => {

    const {request, loading, token} = useHttp();
    
    const[userData,setUserData] = useState([]);

    const location = document.location.search.slice(1);

    const addUserToCourse = useCallback((userId)=>{
        request('/api/course/addtocourse/'+userId, 'PATCH', {courseId: location} ,{Authorization: token})
            .then(data => window.M.toast({html: data.message}))
    },[location,request,token])

    const getUsers = useCallback(()=>{
        request('/api/user', 'GET', null, {Authorization: token}).then(data=>{setUserData(data)})
    },[request,token])

    useEffect(()=>{
        getUsers()
    },[getUsers])

    return(
        <div className="container">
            <h1>Hello from AddToCourse</h1>
            {loading && <p>загрузка...</p>}
            {!loading && userData.map(el=>{
                return <UserItem key={el._id} {...el} addtocourse={addUserToCourse}/>
            })}
        </div>
    )
}

const UserItem = (data) =>{

    return(
        <div className="add-course__user-line">
            <div>{data.userName} {data.userSurname}</div>
            <button onClick={()=>data.addtocourse(data._id)} className="btn">+</button>
        </div>
    )
}