import React, { useContext, useState, useEffect } from "react"
import { NavLink, useHistory } from "react-router-dom"
import { AuthContext } from "../../context/auth.context"

export const Navbar = token => {
    const history = useHistory();
    const auth = useContext(AuthContext);
    const [authif,setAuthif] = useState(null)
    const [name, setName] = useState(null)
    
    useEffect(()=>{
        setAuthif(token.token)
    },[setAuthif,token])

    useEffect(()=>{
        setName(auth.userName);
    },[setName,auth.userName])

    const logoutHandler = event =>{
        // event.preventDefault()
        auth.logout()
        history.push('/')
    }

    const mobileMenuHandler = (evt)=>{
        if(evt.target.href !== "https://yescoding.ru/"){
        evt.preventDefault();
        if(history.location.pathname === '/auth' && (evt.target.parentNode.classList.contains('main-nav__burger') || evt.target.classList.contains('main-nav__burger'))){
            history.push('/')
            return
        }
        evt.target.parentNode.classList.contains("nav-list__el")
        document.querySelector('.main_nav__list').classList.toggle('acitve_mobile')}
    }

    return(
       <nav className="main-navigation">
           <div className="container main_nav__container">
                
                <ul className="main_nav__list" onClick={mobileMenuHandler}>
                    <li className="nav-list__el"><NavLink to="/">Главная</NavLink></li>
                    {auth.isAdmin&&<li><NavLink to="/permissions">Права</NavLink></li>}
                        <li className="nav-list__el"><NavLink to="/instruction">Инструкции</NavLink></li>
                   
                        <li className="nav-name" ><p >{name}</p></li>
                    {authif&&<li className="nav-list__el"><button className="navbar-btn" onClick={logoutHandler}>Выход</button></li>}
                    {!authif&&<li className="nav-list__el"><NavLink className="login_btn" to="/"><b>Вход</b></NavLink> </li>}
                </ul>
                <div className="main-nav__burger" onClick={mobileMenuHandler}>
                    <div className="burger-slice"></div>
                </div>
                <div className="nav__bg-elem">
                    <NavLink to="/" className="header-logo">
                        <img alt="Yes.coding" srcSet="https://static.tildacdn.com/tild6135-3334-4161-b464-643161663264/YesCoding2.svg" width="50px" />
                    </NavLink>
                </div>
           </div>
       </nav> 
    )
} 