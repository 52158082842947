import React, {  useState, useCallback, useEffect } from 'react'
import {useHttp} from '../../hooks/http.hook'
import { Exercise } from './components/exercise';
import { useHistory } from 'react-router-dom';
import { Loading } from '../components/mini/loading';
import { GoBackSider } from '../components/mini/gobacksider';



export const VideoLesson = () => {
    const history = useHistory()
    const {request, token, loading} = useHttp();
    let location = document.location.search.slice(1).split('?course=')
    const course = location[1]
    location = location[0]
    const [data, setData] = useState([{name:"", src:""}])
    const [exercises, setExercises] = useState(null)
    const getData = useCallback(()=>{
        request('/api/videolesson/'+location, 'GET',null, {Authorization: token}).then((response) => {
            setData(response);
            setExercises(
                response[1].map((el,i)=>{
                    return <Exercise data={el} key={i} location={location} courseId={course}/>
                })
            )
        })
    },[location,request,token,course])


    useEffect(()=>{
        window.scrollTo(0,0);
        getData()
    },[getData])
    
const goBack = useCallback(()=>{
    if(course === undefined) return history.goBack();
    history.push('/videocourse/?'+course)
},[history, course])

return(
    <div className="container vlesson">
        <h1 className="page-headline">{data[0].name}</h1>
        <iframe className="vlesson__iframe" title="d1" width="560" height="315" src={data[0].src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        {loading && <Loading />}
        {!loading && <>
        {data[0].helpLink !== undefined && <div className="vlessons__help-link">
            <a href={data[0].helpLink}>Полезные материалы</a>
            </div>}
        <div>
            {exercises}
        </div>
        <div className="goback_container">
            <div className="back_btn" onClick={goBack}>Назад к урокам</div>
        </div>
        </>}
        {course !== undefined ?
            <GoBackSider path={'/videocourse/?'+course}/>
            :
            <GoBackSider />
        }
        
    </div>
)
}
