import React, {useState} from "react"
import { useHttp } from "../../../hooks/http.hook"

export const Updater = data => {
    const token = JSON.parse(localStorage.getItem("userData"))? JSON.parse(localStorage.getItem("userData")).token : null;
    const {request} = useHttp();
    const [form,setForm] = useState({
        name: data.name, teacher:data.teacher,teacherId:data.teacherId,schedule:data.schedule,discordId: data.discord
    })
    const changeHandler = evt => {
        setForm({...form, [evt.target.name]: evt.target.value})
    }
    const updateHandler = (e) => { 
        request('/api/groups/'+data.groupId, 'PATCH',{...form}, {Authorization: token}).then(()=>{
            window.M.toast({html: "Группа обновлена"})
        })
        const corredItem = e.target.parentNode.parentNode.parentNode.parentNode;
        corredItem.querySelector(".group_name").textContent = form.name;
        corredItem.querySelector(".group_teacher").textContent = form.teacher;
        corredItem.querySelector(".group_schedule").textContent = form.schedule;

        document.querySelectorAll('input').forEach(e=>{
            e.value = ""
        })
    }

    return(
        <div className="updater-wrap">
            <div className="updater-list">
            <div className="input-field">
                    <input id={"name_cor_"+data._id} name="name" type="text" className="group-creating"  onChange={changeHandler}/>
                    <label htmlFor={"name_cor_"+data._id}>Название </label>
                </div>
                <div className="input-field">
                    <input id={"teacher_cor"+data._id} name="teacher" type="text" className="group-creating" onChange={changeHandler}/>
                    <label htmlFor={"teacher_cor"+data._id}>Имя преподавателя</label>
                </div>
                <div className="input-field">
                    <input id={"teacherId_cor"+data._id} name="teacherId" type="text" className="group-creating" onChange={changeHandler}/>
                    <label htmlFor={"teacherId_cor"+data._id}>ID преподавателя</label>
                </div>
                <div className="input-field">
                    <input id={"discordId_cor"+data._id} name="discordId" type="text" className="group-creating" onChange={changeHandler}/>
                    <label htmlFor={"discordId_cor"+data._id}>Link discord</label>
                </div>
                <div className="input-field">
                    <input id={"schedule_cor"+data._id} name="schedule" type="text" className="group-creating" onChange={changeHandler}/>
                    <label htmlFor={"schedule_cor"+data._id}>Время занятий</label>
                </div>
                <div className="btn-wrap">
                    <button className="btn waves-effect waves-light" onClick={updateHandler}>Обновить</button>
                </div>
            </div>
        </div>
    )
}