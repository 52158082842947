import React, { useCallback, useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { GoBackSider } from '../components/mini/gobacksider';
import { CoursesElem } from './components/courseselem';

export const Courses = () =>{
    const {request, token, loading} = useHttp();
    const [coursesData, setCoursesData] = useState([])

    const GetCourseList = useCallback(()=>{
        request('/api/course', 'GET',null, {Authorization: token}).then(data=>{setCoursesData(data)})
    },[request, token])

    useEffect(()=>{
        GetCourseList()
    },[GetCourseList])

    return(
        <div className="pattern-bg">
            <div className="course__container">
                <h1 className="page-headline headline--shadow">Курсы</h1>
                <div className="course-list">
                    {!loading && coursesData.map((el,i)=><CoursesElem key={i} {...el}/>)}
                </div>
            </div>
            <GoBackSider />
        </div>
    )
}