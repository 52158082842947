import React from 'react';
import { NavLink } from 'react-router-dom';

export const MPCards = ({headline, content, linkTo="?", blue=false}) => {
    const classContent = blue ? "cards-grid__item blue-card" : "cards-grid__item";

    return (
        <NavLink to={linkTo} className={classContent}>
            <h3 className="grid-item__h3">{headline}</h3>
            <p className="grid-item__p">{content}</p>
            <div className="grid-item__button">Подробнее</div>
        </NavLink>
    )
}