import React, {useState} from "react";
import { useHttp } from "../../../hooks/http.hook";


export const Visitors = data =>{
    const token = JSON.parse(localStorage.getItem("userData"))? JSON.parse(localStorage.getItem("userData")).token : null;

    const {request} = useHttp();
    const [checked,setChecked] = useState(data.checked)
    const changeHandler = e => {
        setChecked(ch=>!ch);
        const body = {
            toggler: !checked,
            attendanceListItem : data.date
        }

        request('/api/user/attendance/'+ data.userId, 'PATCH', body, {Authorization: token})
    }
    return(
        <div className="visitors-wrap">
            <label>
                <input type="checkbox" checked={checked} onChange={changeHandler}/>
                <span></span>
            </label>
        <div className="visitors-wrap__hours">{data.hoursList}</div>
        </div>
    )
}
