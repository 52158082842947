import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import {useHttp} from '../hooks/http.hook'
import { Loading } from './components/mini/loading';
import {UserItem} from './components/userItem'

export const PermissionsPage = ()=>{
    const {request, loading, token} = useHttp();
    
    const[userData,setUserData] = useState(null)

    const getUsers = useCallback(()=>{
        request('/api/user', 'GET', null, {Authorization: token}).then(data=>{setUserData(data)})
    },[request,token])

    useEffect(()=>{
        getUsers()
    },[getUsers])

    return (
        <div className="container">
            <h1 className="page-headline">Присвоение прав</h1>
            <NavLink className="homing-btn mb-25" to='/trans-list'>На страницу транзакций</NavLink>
            <div className="collection">
                {loading&& <Loading/>}
                {!loading && userData && <UserItem users={userData} perms={true}/>}
            </div>
        </div>
    )
}